<script setup>
import { DevFootcareModel } from "@/models";

let deferredPrompt;

import { useRouter } from "vue-router";

const router = useRouter();

const show = localStorage.getItem("show_app_installer");

if (!show) {
  localStorage.setItem("show_app_installer", "true");
}

window.addEventListener("beforeinstallprompt", (e) => {
  // Prevents the default mini-infobar or install dialog from appearing on mobile
  e.preventDefault();
  // Save the event because you'll need to trigger it later.
  deferredPrompt = e;
  // Show your customized install prompt for your PWA
  // Your own UI doesn't have to be a single element, you
  // can have buttons in different locations, or wait to prompt
  // as part of a critical journey.
  // console.log(router.options["history"].state.back)
  // if (router.options["history"].state.back) {

  if (show !== "false") {
    showInAppInstallPromotion();
    console.log("install");
  }
  // }
});

async function showInAppInstallPromotion() {
  async function presentAlertConfirm() {
    const alert = await alertController.create({
      header: "U kunt Fittr ook als App installeren!",
      message: "Wilt u deze app installeren?",
      buttons: [
        {
          text: "Nee",
          role: "cancel",
          cssClass: "secondary",
          id: "cancel-button",
          handler: (blah) => {
            localStorage.setItem("show_app_installer", "false");
            console.log("Confirm Cancel:", blah);
          },
        },
        {
          text: "Ja",
          id: "confirm-button",
          handler: async () => {
            //localStorage.setItem('show_app_installer', false);
            console.log("Confirm Okay");
            deferredPrompt.prompt();
          },
        },
      ],
    });
    return alert.present();
  }

  await presentAlertConfirm();
}

import {
  IonAvatar,
  IonButtons,
  IonBadge,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLabel,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonThumbnail,
  IonToolbar,
  IonButton,
  alertController,
} from "@ionic/vue";

import { useQuasar, QInnerLoading } from "quasar";

import { DataStore, SortDirection } from "aws-amplify/datastore";

import { ref } from "vue";
import { useGlobalStore } from "../store/global";

const store = useGlobalStore();
const $q = useQuasar();
const category = ref();

const osaVisible = ref(false);
const osbVisible = ref(false);
const measurementsVisible = ref(false);
const lastsVisible = ref(false);

const segmentChanged = (ev) => {
  category.value = ev.detail.value;
  store.category = ev.detail.value;
};
const navigateOSA = async () => {
  // Early returns when conditions aren't met
  if (!store.logged_in_user["custom:organisation"].includes("Hanssen")) {
    showAlert("Deze functie is momenteel niet beschikbaar!");
    return;
  }
  if (!category.value) {
    showAlert(
      "Om verder te gaan moet er eerst een doelgroep worden geselecteerd!"
    );
    return;
  }

  osaVisible.value = true;

  // Fetch and filter models
  const temp = await DataStore.query(
    DevFootcareModel,
    (c) => c.category.contains(category.value),
    {
      sort: (s) =>
        s
          .modelnumber(SortDirection.ASCENDING)
          .colornumber(SortDirection.ASCENDING),
    }
  );

  // Recursively call the function when there are no models
  if (temp.length === 0) {
    return navigateOSA();
  }

  // Use map function
  const urlModels = temp.map((model) => ({ modelID: model.id }));

  // Updating store properties
  store.models = [];
  store.initialisedModels = [];
  store.cheapModels = [];
  store.expensiveModels = [];
  store.imageLoadingState = false;
  store.filterLoadingState = false;
  store.modelCollection = urlModels;
  store.footData = undefined;
  store.lastProposals.footData = undefined;

  await router.push(
    "/osa/models/" + JSON.stringify({ category: category.value })
  );

  osaVisible.value = false;
};

const navigateOSB = async () => {
  if (!category.value) {
    showAlert(
      "Om verder te gaan moet er eerst een doelgroep worden geselecteerd!"
    );
    return;
  }

  osbVisible.value = true;

  const temp = await DataStore.query(store.Models, (c) => c.and(c => [
    c.category.contains(category.value),
    c[store.organisation].eq(true)
  ]),{
    sort: (s) =>
        s
            .brand(SortDirection.ASCENDING)
            .modelnumber(SortDirection.ASCENDING)
            .colornumber(SortDirection.ASCENDING),
  });

  /* const temp = await DataStore.query(
    store.Models,
    (c) => c.category.contains(category.value),
    {
      sort: (s) =>
        s
          .brand(SortDirection.ASCENDING)
          .modelnumber(SortDirection.ASCENDING)
          .colornumber(SortDirection.ASCENDING),
    }
  ); */

  // if temp is empty, attempt to navigate again.
  if (temp.length === 0) {
    return navigateOSB();
  }

  const urlModels = temp;

  store.models = [];
  store.initialisedModels = [];
  store.cheapModels = [];
  store.expensiveModels = [];
  store.imageLoadingState = false;
  store.filterLoadingState = false;

  store.modelCollection = [];
  store.modelCollection = urlModels;
  store.footData = undefined;
  store.lastProposals.footData = undefined;

  await router.push(
    "/osb/models/" + JSON.stringify({ category: category.value })
  );

  osbVisible.value = false;
};

const navigateMeasurements = async () => {
  if (category.value) {
    measurementsVisible.value = true;
    await router.push("/osb/foot-dimensions/" + category.value);
    measurementsVisible.value = false;
  } else {
    showAlert(
      "Om verder te gaan moet er eerst een doelgroep worden geselecteerd!"
    );
  }
};

const navigateLasttypes = async () => {
  if (category.value) {
    lastsVisible.value = true;
    await router.push("/osb/alternative-lasts/" + category.value);
    lastsVisible.value = false;
  } else {
    showAlert(
      "Om verder te gaan moet er eerst een doelgroep worden geselecteerd!"
    );
  }
};

const showAlert = (message) => {
  $q.notify({
    color: "red-5",
    position: "center",
    textColor: "white",
    icon: "fas fa-triangle-exclamation",
    message: message,
  });
};

</script>

<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar color="none">
        <ion-buttons slot="start">
          <!--          <img class="header-logo" alt="no-image" src="../assets/logo.png" />-->
          <img
            class="header-logo"
            alt="no-image"
            :src="store.app_logo.toString()"
          />
        </ion-buttons>
        <ion-buttons
          slot="end"
          style="color: var(--ion-color-secondary); margin-right: 10px"
        >
          <ion-button style="color: var(--ion-color-light)">
            <ion-menu-toggle
              style="
                z-index: 200;
                position: absolute;
                width: 100px;
                height: 100%;
              "
            ></ion-menu-toggle>
            <i style="font-size: 24px" class="fa-regular fa-bars"></i>
            <ion-badge
              color="secondary"
              style="
                display: none;
                z-index: 100;
                position: fixed;
                right: 0;
                top: 0;
              "
              :style="
                !store.logged_in_user ? 'display: block' : 'display: none'
              "
            >
              <i class="fa-regular fa-user-xmark"></i
            ></ion-badge>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class="background-image">
      <div slot="fixed" class="homepage-overlay">
        <ion-grid>
          <ion-row class="ion-align-items-end">
            <ion-col
              size-xs="6"
              size-sm="6"
              size-md="3"
              size-lg="3"
              size-xl="3"
            >
              <ion-card button @click="navigateOSA()">
                <div class="card-overlay-osa"></div>

                <ion-card-header>
                  <ion-thumbnail>
                    <ion-avatar>
                      <img alt="no image" src="../assets/img/book.jpg" />
                    </ion-avatar>
                  </ion-thumbnail>
                  <ion-card-subtitle
                    style="border-bottom: 1px rgba(175, 175, 183, 1) solid"
                    >OS<span class="os-type"> A</span>
                  </ion-card-subtitle>
                  <ion-card-title>Catalogus</ion-card-title>
                </ion-card-header>

                <q-inner-loading :showing="osaVisible">
                  <ion-spinner></ion-spinner>
                </q-inner-loading>
              </ion-card>
            </ion-col>

            <ion-col
              size-xs="6"
              size-sm="6"
              size-md="3"
              size-lg="3"
              size-xl="3"
            >
              <ion-card
                style="--background: var(--ion-color-secondary)"
                button
                @click="navigateMeasurements()"
              >
                <div class="card-overlay"></div>

                <ion-card-header>
                  <ion-thumbnail>
                    <ion-avatar>
                      <img
                        alt="no image"
                        src="../assets/img/measurements.jpg"
                      />
                    </ion-avatar>
                  </ion-thumbnail>
                  <ion-card-subtitle
                    style="border-bottom: 1px rgba(175, 175, 183, 1) solid"
                    >OS<span class="os-type"> B</span>
                  </ion-card-subtitle>
                  <ion-card-title>Voetmaten</ion-card-title>
                </ion-card-header>

                <q-inner-loading :showing="measurementsVisible">
                  <ion-spinner></ion-spinner>
                </q-inner-loading>
              </ion-card>
            </ion-col>

            <ion-col
              size-xs="6"
              size-sm="6"
              size-md="3"
              size-lg="3"
              size-xl="3"
            >
              <ion-card
                style="--background: var(--ion-color-secondary)"
                button
                @click="navigateLasttypes()"
              >
                <div class="card-overlay"></div>

                <ion-card-header>
                  <ion-thumbnail>
                    <ion-avatar>
                      <img alt="no image" src="../assets/img/lasts.jpg" />
                    </ion-avatar>
                  </ion-thumbnail>
                  <ion-card-subtitle
                    style="border-bottom: 1px rgba(175, 175, 183, 1) solid"
                    >OS<span class="os-type"> B</span>
                  </ion-card-subtitle>
                  <ion-card-title>Leesttypen</ion-card-title>
                </ion-card-header>

                <q-inner-loading :showing="lastsVisible">
                  <ion-spinner></ion-spinner>
                </q-inner-loading>
              </ion-card>
            </ion-col>

            <ion-col
              size-xs="6"
              size-sm="6"
              size-md="3"
              size-lg="3"
              size-xl="3"
              @click="navigateOSB()"
            >
              <ion-card
                style="--background: var(--ion-color-secondary); z-index: 0"
                button
              >
                <div class="card-overlay"></div>

                <ion-card-header>
                  <ion-thumbnail>
                    <ion-avatar>
                      <img alt="no image" src="../assets/img/osb.jpg" />
                    </ion-avatar>
                  </ion-thumbnail>
                  <ion-card-subtitle
                    style="border-bottom: 1px rgba(175, 175, 183, 1) solid"
                    >OS<span class="os-type"> B</span>
                  </ion-card-subtitle>
                  <ion-card-title>Catalogus</ion-card-title>
                </ion-card-header>

                <q-inner-loading :showing="osbVisible">
                  <ion-spinner></ion-spinner>
                </q-inner-loading>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>

    <ion-footer>
      <ion-toolbar style="display: flex; align-items: center" color="primary">
<!--        <ion-toolbar style="display: flex; align-items: center" color="light">-->
        <ion-segment @ionChange="segmentChanged($event)" color="secondary">
          <ion-segment-button value="f" style="width: 100%">
            <i class="fa-thin fa-female footer-icon"></i>
            <ion-label style="margin-top: 0; margin-bottom: 0"
              >Dames
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="m" style="width: 100%">
            <i class="fa-thin fa-male footer-icon"></i>
            <ion-label style="margin-top: 0; margin-bottom: 0"
              >Heren
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="k" style="width: 100%">
            <i class="fa-thin fa-child footer-icon"></i>
            <ion-label style="margin-top: 0; margin-bottom: 0">Kids</ion-label>
          </ion-segment-button>
          <ion-segment-button value="w" style="width: 100%">
            <i class="fa-thin fa-user-helmet-safety footer-icon"></i>
            <ion-label style="margin-top: 0; margin-bottom: 0"
              >Safety
            </ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<style scoped>
ion-footer ion-toolbar {
  background-color: var(--ion-color-primary);
}
ion-footer {
  background-color: var(--ion-color-primary);
}

ion-segment i {
  color: var(--ion-color-secondary);
}

ion-label {
  color: var(--ion-color-secondary);
}

.homepage-overlay {
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  /*background: var(--ion-color-primary), opacity(0.15);*/
  background: hsla(0, 0%, 84.3%, 0.7);
}

.card-overlay-osa {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  /*background: var(--ion-color-primary);
  opacity: 0.9;*/
  /* background: rgba(75, 75, 93, 0.5);*/
  background: rgba(125, 125, 123, 0.5);
}
/*ion-footer ion-toolbar {
  background-color: var(--ion-color-light);
}
ion-segment i {
  color: var(--ion-color-primary);
}

ion-label {
  color: var(--ion-color-primary);
}

.homepage-overlay {
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  background: var(--ion-color-primary), opacity(0.15);
}

.card-overlay-osa {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: var(--ion-color-primary);
  opacity: 0.9;
}*/

@media (min-width: 350px) {
  ion-grid {
    height: 100%;
    width: 100%;
  }

  ion-row {
    width: 100%;
    height: 100%;
    padding-bottom: calc(80px + var(--ion-safe-area-bottom));
    align-content: flex-end;
  }

  .os-type {
    color: rgba(255, 255, 255, 0.75);
    font-size: 1.6rem;
  }

  ion-content {
    --background: rgba(75, 75, 93, 0.7);
  }

  .background-image {
    position: absolute;
    height: 100vh;
    width: 100%;
    background-image: url(../assets/img/homepage.jpg);
    background-position: top;
    background-size: cover;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
  }

  .card-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(75, 75, 93, 0.5);
  }

  ion-segment {
    width: 90%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .md ion-segment {
    left: 5%;
  }

  ion-segment-button {
    padding-top: 5px;
    width: 70px;
  }

  .footer-icon {
    font-size: 1.5em;
  }

  ion-avatar {
    width: 55px;
    height: 55px;
  }

  ion-card {
    --background: var(--ion-color-secondary);
    --padding-inline-start: 5px;
    --padding-top: 5px;
    border-radius: 15px;
    margin: 10px;
  }

  ion-card-subtitle {
    margin: 10px 0 5px;
    border-bottom: 1px rgba(75, 75, 83, 1) solid;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 5px;
    font-size: 1rem;
    text-align: right;
  }

  ion-card-title {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.1rem;
    text-align: center;
  }

  ion-toolbar {
    background-image: none !important;
  }
}

/*###############################################################*/
@media (min-width: 576px) {
  ion-row {
    width: 100%;
    height: 100%;
    padding-bottom: calc(130px + var(--ion-safe-area-bottom));
    align-content: flex-end;
  }

  ion-card {
    --background: var(--ion-color-secondary-shade);
    --padding-inline-start: 5px;
    --padding-top: 5px;
    border-radius: 15px;
    margin: 30px;
  }

  .homepage-overlay {
    padding-left: 20px;
    padding-right: 20px;
  }

  ion-card-subtitle {
    margin: 20px 0 10px;
    border-bottom: 1px rgba(75, 75, 83, 1) solid;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 5px;
    font-size: 1.3rem;
    text-align: right;
  }

  ion-card-title {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.4rem;
    text-align: center;
  }

  ion-segment {
    width: 60%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .md ion-segment {
    left: 20%;
  }
}

/*###############################################################*/
@media (min-width: 768px) {
  ion-segment-button {
    width: 100px;
  }
}

/*###############################################################*/
@media (min-width: 992px) {
  ion-segment-button {
    width: 125px;
  }
}

/*###############################################################*/
@media (min-width: 1024px) {
}

/*###############################################################*/
@media (min-width: 1150px) {
}
</style>
