<script setup>
import { ref } from "vue";

import {
  QSkeleton,
  useQuasar,
} from "quasar";

import {
  IonItem,
  IonList,
  IonCard,
  IonCardContent,
  IonLabel,
  IonCheckbox,
  IonListHeader,
} from "@ionic/vue";

import { useRoute } from "vue-router";

import { useGlobalStore } from "../store/global";

const store = useGlobalStore();
const content = ref();
const route = useRoute();

const loader = ref();

const $q = useQuasar();

const props = defineProps({
  items: Array,
  title: String,
  propName: String,
  radio: Boolean,
  model: { required: true },
});

// function unique(array, propertyName) {
//   return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
// }
//
// function modelExists(arr, modelID) {
//   return arr.some(function (el) {
//     return el.modelID === modelID;
//   });
// }

const capitalize = (value) => {
  if (!value) {
    return "";
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

const update = async (propName, item) => {
  store.imageLoadingState = false;
  //console.log(propName);
  //console.log(item);
  const amount = await store.filterModels(propName, item.id);
  if (amount === 0) {
    $q.notify({
      color: "red-5",
      position: "center",
      textColor: "white",
      icon: "fas fa-triangle-exclamation",
      message: "Helaas, deze zoekopdracht geeft geen resultaten",
    });
    // setTimeout(() => {
    // if (store.models.length === 0) {
    store.models = store.initialisedModels;
    // store.methods.filter();
    // }
    // }, 6000)
  }
  store.imageLoadingState = true;
};
</script>

<template>
  <ion-item
    lines="none"
    style="
      flex: 1 0 100%;
      --inner-padding-end: 0;
      --inner-padding-start: 0;
      --padding-start: 0;
      --padding-end: 0;
    "
    class="ion-no-margin ion-no-border"
  >
    <ion-card v-if="!radio" ref="content" style="width: 100%">
      <ion-card-content>
        <template v-if="!store.filterLoadingState">
          <ion-list
            :style="
              store.filterLoadingState
                ? 'z-index:0;opacity:0'
                : 'z-index:2;opacity:1'
            "
            ref="loader"
            style="transition: opacity 0.5s"
          >
            <ion-list-header style="opacity: 0.95">
              <q-skeleton
                type="rect"
                animation="fade"
                height="26px"
                width="80px"
              />
            </ion-list-header>
            <template v-for="i in 4" :key="i">
              <ion-item style="opacity: 0.95" :detail="false" lines="inset">
                <q-skeleton
                  animation="fade"
                  style="margin: 12px"
                  size="26px"
                  type="rect"
                />
                <q-skeleton
                  animation="fade"
                  style="margin-left: 2px"
                  width="70px"
                  type="QBadge"
                />
              </ion-item>
              <ion-item
                style="opacity: 0.95"
                v-if="i < 4"
                :detail="false"
                lines="inset"
              >
                <q-skeleton
                  animation="fade"
                  style="margin: 12px"
                  size="26px"
                  type="rect"
                />
                <q-skeleton
                  animation="fade"
                  style="margin-left: 2px"
                  width="50px"
                  type="QBadge"
                />
              </ion-item>
            </template>
          </ion-list>
        </template>


        <template v-else>
          <ion-list
            style="transition: opacity 0.5s"
            :style="
              store.filterLoadingState
                ? 'z-index:2;opacity:1'
                : 'z-index:0;opacity:0'
            "
          >
            <ion-list-header>
              <ion-label>{{ title }}</ion-label>
            </ion-list-header>

            <template v-if="propName !== 'brand'">
              <ion-item
                v-for="(item, key) in items"
                :key="key"
                :detail="false"
                lines="full"
              >
<!--                <ion-label>{{ capitalize(item.item) }}</ion-label>-->
                <ion-checkbox
                  :checked="false"
                  justify="start"
                  label-placement="end"
                  slot="start"
                  color="secondary"
                  :val="item.id"
                  @update:modelValue="update(propName, item)"
                >{{ capitalize(item.item) }}
                </ion-checkbox>
              </ion-item>
            </template>

            <template
              v-else-if="route.path.includes('osb') && propName === 'brand'"
            >
              <ion-item
                v-for="(item, key) in items"
                :key="key"
                :detail="false"
                lines="inset"
              >
                <ion-label>{{ capitalize(item.label) }}</ion-label>
                <ion-checkbox
                  :aria-label="capitalize(item.label)"
                  :checked="false"
                  slot="start"
                  justify="start"
                  color="secondary"
                  size="large"
                  :val="item.id"
                  @update:modelValue="update(propName, item)"
                >
                </ion-checkbox>
              </ion-item>
            </template>
          </ion-list>
        </template>
      </ion-card-content>
    </ion-card>
  </ion-item>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
