/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDevFittrLast = /* GraphQL */ `
  query GetDevFittrLast($id: ID!) {
    getDevFittrLast(id: $id) {
      id
      ballsize
      ballwidth
      brand
      calculate_default_insole
      heelheight
      heelinstepsize
      lastgroup
      lastlength
      lasttype
      targetgroup
      category
      shoesize
      supplier
      toeheight
      toespring
      editors
      fittr
      penders
      hanssen
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFittrLasts = /* GraphQL */ `
  query ListDevFittrLasts(
    $filter: ModelDevFittrLastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFittrLasts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ballsize
        ballwidth
        brand
        calculate_default_insole
        heelheight
        heelinstepsize
        lastgroup
        lastlength
        lasttype
        targetgroup
        category
        shoesize
        supplier
        toeheight
        toespring
        editors
        fittr
        penders
        hanssen
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFittrLasts = /* GraphQL */ `
  query SyncDevFittrLasts(
    $filter: ModelDevFittrLastFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFittrLasts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ballsize
        ballwidth
        brand
        calculate_default_insole
        heelheight
        heelinstepsize
        lastgroup
        lastlength
        lasttype
        targetgroup
        category
        shoesize
        supplier
        toeheight
        toespring
        editors
        fittr
        penders
        hanssen
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFittrModel = /* GraphQL */ `
  query GetDevFittrModel($id: ID!) {
    getDevFittrModel(id: $id) {
      id
      category
      attributes
      lastgroup
      lasttypes
      sizes
      modelnumber
      colornumber
      filename
      brand
      sizerangeMin
      sizerangeMax
      prices
      editors
      fittr
      penders
      hanssen
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFittrModels = /* GraphQL */ `
  query ListDevFittrModels(
    $filter: ModelDevFittrModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFittrModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        attributes
        lastgroup
        lasttypes
        sizes
        modelnumber
        colornumber
        filename
        brand
        sizerangeMin
        sizerangeMax
        prices
        editors
        fittr
        penders
        hanssen
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFittrModels = /* GraphQL */ `
  query SyncDevFittrModels(
    $filter: ModelDevFittrModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFittrModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        category
        attributes
        lastgroup
        lasttypes
        sizes
        modelnumber
        colornumber
        filename
        brand
        sizerangeMin
        sizerangeMax
        prices
        editors
        fittr
        penders
        hanssen
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFittrOrder = /* GraphQL */ `
  query GetDevFittrOrder($id: ID!) {
    getDevFittrOrder(id: $id) {
      id
      order_destination
      product_destination_address
      clientID
      organisation
      userName
      orderIdOne
      orderIdTwo
      filename
      status
      createdAt
      submittedAt
      exportedAt
      editors
      clientGeneralEmail
      formData
      search
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFittrOrders = /* GraphQL */ `
  query ListDevFittrOrders(
    $filter: ModelDevFittrOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFittrOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order_destination
        product_destination_address
        clientID
        organisation
        userName
        orderIdOne
        orderIdTwo
        filename
        status
        createdAt
        submittedAt
        exportedAt
        editors
        clientGeneralEmail
        formData
        search
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFittrOrders = /* GraphQL */ `
  query SyncDevFittrOrders(
    $filter: ModelDevFittrOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFittrOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        order_destination
        product_destination_address
        clientID
        organisation
        userName
        orderIdOne
        orderIdTwo
        filename
        status
        createdAt
        submittedAt
        exportedAt
        editors
        clientGeneralEmail
        formData
        search
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFittrClosing = /* GraphQL */ `
  query GetDevFittrClosing($id: ID!) {
    getDevFittrClosing(id: $id) {
      id
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFittrClosings = /* GraphQL */ `
  query ListDevFittrClosings(
    $filter: ModelDevFittrClosingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFittrClosings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFittrClosings = /* GraphQL */ `
  query SyncDevFittrClosings(
    $filter: ModelDevFittrClosingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFittrClosings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFittrStyle = /* GraphQL */ `
  query GetDevFittrStyle($id: ID!) {
    getDevFittrStyle(id: $id) {
      id
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFittrStyles = /* GraphQL */ `
  query ListDevFittrStyles(
    $filter: ModelDevFittrStyleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFittrStyles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFittrStyles = /* GraphQL */ `
  query SyncDevFittrStyles(
    $filter: ModelDevFittrStyleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFittrStyles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFittrColor = /* GraphQL */ `
  query GetDevFittrColor($id: ID!) {
    getDevFittrColor(id: $id) {
      id
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFittrColors = /* GraphQL */ `
  query ListDevFittrColors(
    $filter: ModelDevFittrColorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFittrColors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFittrColors = /* GraphQL */ `
  query SyncDevFittrColors(
    $filter: ModelDevFittrColorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFittrColors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFittrSpec = /* GraphQL */ `
  query GetDevFittrSpec($id: ID!) {
    getDevFittrSpec(id: $id) {
      id
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFittrSpecs = /* GraphQL */ `
  query ListDevFittrSpecs(
    $filter: ModelDevFittrSpecFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFittrSpecs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFittrSpecs = /* GraphQL */ `
  query SyncDevFittrSpecs(
    $filter: ModelDevFittrSpecFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFittrSpecs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFittrHeight = /* GraphQL */ `
  query GetDevFittrHeight($id: ID!) {
    getDevFittrHeight(id: $id) {
      id
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFittrHeights = /* GraphQL */ `
  query ListDevFittrHeights(
    $filter: ModelDevFittrHeightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFittrHeights(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFittrHeights = /* GraphQL */ `
  query SyncDevFittrHeights(
    $filter: ModelDevFittrHeightFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFittrHeights(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFittrPurchasePrice = /* GraphQL */ `
  query GetDevFittrPurchasePrice($id: ID!) {
    getDevFittrPurchasePrice(id: $id) {
      id
      category
      lasttype
      modelnumber
      colornumber
      modelname
      colorname
      supplierCatNumber
      brand
      supplier
      sizerangeMax
      sizerangeMin
      closing
      exceptions
      leathertype
      price
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFittrPurchasePrices = /* GraphQL */ `
  query ListDevFittrPurchasePrices(
    $filter: ModelDevFittrPurchasePriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFittrPurchasePrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        lasttype
        modelnumber
        colornumber
        modelname
        colorname
        supplierCatNumber
        brand
        supplier
        sizerangeMax
        sizerangeMin
        closing
        exceptions
        leathertype
        price
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFittrPurchasePrices = /* GraphQL */ `
  query SyncDevFittrPurchasePrices(
    $filter: ModelDevFittrPurchasePriceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFittrPurchasePrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        category
        lasttype
        modelnumber
        colornumber
        modelname
        colorname
        supplierCatNumber
        brand
        supplier
        sizerangeMax
        sizerangeMin
        closing
        exceptions
        leathertype
        price
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFittrPriceLimit = /* GraphQL */ `
  query GetDevFittrPriceLimit($id: ID!) {
    getDevFittrPriceLimit(id: $id) {
      id
      amount
      organisation
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFittrPriceLimits = /* GraphQL */ `
  query ListDevFittrPriceLimits(
    $filter: ModelDevFittrPriceLimitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFittrPriceLimits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        organisation
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFittrPriceLimits = /* GraphQL */ `
  query SyncDevFittrPriceLimits(
    $filter: ModelDevFittrPriceLimitFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFittrPriceLimits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        amount
        organisation
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevModelCollection = /* GraphQL */ `
  query GetDevModelCollection($id: ID!) {
    getDevModelCollection(id: $id) {
      id
      activeModels
      footData
      isLink
      category
      lastgroup
      models
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevModelCollections = /* GraphQL */ `
  query ListDevModelCollections(
    $filter: ModelDevModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevModelCollections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activeModels
        footData
        isLink
        category
        lastgroup
        models
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevModelCollections = /* GraphQL */ `
  query SyncDevModelCollections(
    $filter: ModelDevModelCollectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevModelCollections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        activeModels
        footData
        isLink
        category
        lastgroup
        models
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFootcareWelt = /* GraphQL */ `
  query GetFootcareWelt($id: ID!) {
    getFootcareWelt(id: $id) {
      id
      filename
      item
      typ
      color
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFootcareWelts = /* GraphQL */ `
  query ListFootcareWelts(
    $filter: ModelFootcareWeltFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFootcareWelts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filename
        item
        typ
        color
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFootcareWelts = /* GraphQL */ `
  query SyncFootcareWelts(
    $filter: ModelFootcareWeltFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFootcareWelts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        filename
        item
        typ
        color
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFootcareApplicatie = /* GraphQL */ `
  query GetFootcareApplicatie($id: ID!) {
    getFootcareApplicatie(id: $id) {
      id
      filename
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFootcareApplicaties = /* GraphQL */ `
  query ListFootcareApplicaties(
    $filter: ModelFootcareApplicatieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFootcareApplicaties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        filename
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFootcareApplicaties = /* GraphQL */ `
  query SyncFootcareApplicaties(
    $filter: ModelFootcareApplicatieFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFootcareApplicaties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        filename
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFootcareOutsole = /* GraphQL */ `
  query GetFootcareOutsole($id: ID!) {
    getFootcareOutsole(id: $id) {
      id
      balbreedte
      color
      filename
      gelengbreedte
      gewicht
      item
      plaatvorm
      shorewaarde
      slijtweerstand
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFootcareOutsoles = /* GraphQL */ `
  query ListFootcareOutsoles(
    $filter: ModelFootcareOutsoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFootcareOutsoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        balbreedte
        color
        filename
        gelengbreedte
        gewicht
        item
        plaatvorm
        shorewaarde
        slijtweerstand
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFootcareOutsoles = /* GraphQL */ `
  query SyncFootcareOutsoles(
    $filter: ModelFootcareOutsoleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFootcareOutsoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        balbreedte
        color
        filename
        gelengbreedte
        gewicht
        item
        plaatvorm
        shorewaarde
        slijtweerstand
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFootcareUpperleather = /* GraphQL */ `
  query GetFootcareUpperleather($id: ID!) {
    getFootcareUpperleather(id: $id) {
      id
      filename
      item
      item_name
      product_code
      supplier
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFootcareUpperleathers = /* GraphQL */ `
  query ListFootcareUpperleathers(
    $filter: ModelFootcareUpperleatherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFootcareUpperleathers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        filename
        item
        item_name
        product_code
        supplier
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFootcareUpperleathers = /* GraphQL */ `
  query SyncFootcareUpperleathers(
    $filter: ModelFootcareUpperleatherFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFootcareUpperleathers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        filename
        item
        item_name
        product_code
        supplier
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFootcareBadslipper = /* GraphQL */ `
  query GetFootcareBadslipper($id: ID!) {
    getFootcareBadslipper(id: $id) {
      id
      item
      filename
      color
      bijzonderheden
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFootcareBadslippers = /* GraphQL */ `
  query ListFootcareBadslippers(
    $filter: ModelFootcareBadslipperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFootcareBadslippers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        filename
        color
        bijzonderheden
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFootcareBadslippers = /* GraphQL */ `
  query SyncFootcareBadslippers(
    $filter: ModelFootcareBadslipperFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFootcareBadslippers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        filename
        color
        bijzonderheden
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFootcareMaatpantoffel = /* GraphQL */ `
  query GetFootcareMaatpantoffel($id: ID!) {
    getFootcareMaatpantoffel(id: $id) {
      id
      item
      filename
      color
      bijzonderheden
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFootcareMaatpantoffels = /* GraphQL */ `
  query ListFootcareMaatpantoffels(
    $filter: ModelFootcareMaatpantoffelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFootcareMaatpantoffels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        filename
        color
        bijzonderheden
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFootcareMaatpantoffels = /* GraphQL */ `
  query SyncFootcareMaatpantoffels(
    $filter: ModelFootcareMaatpantoffelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFootcareMaatpantoffels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        filename
        color
        bijzonderheden
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFootcareComfortslipper = /* GraphQL */ `
  query GetFootcareComfortslipper($id: ID!) {
    getFootcareComfortslipper(id: $id) {
      id
      item
      filename
      color
      bijzonderheden
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFootcareComfortslippers = /* GraphQL */ `
  query ListFootcareComfortslippers(
    $filter: ModelFootcareComfortslipperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFootcareComfortslippers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        filename
        color
        bijzonderheden
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFootcareComfortslippers = /* GraphQL */ `
  query SyncFootcareComfortslippers(
    $filter: ModelFootcareComfortslipperFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFootcareComfortslippers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        filename
        color
        bijzonderheden
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFootcareUitwasbareschoen = /* GraphQL */ `
  query GetFootcareUitwasbareschoen($id: ID!) {
    getFootcareUitwasbareschoen(id: $id) {
      id
      item
      filename
      color
      bijzonderheden
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFootcareUitwasbareschoens = /* GraphQL */ `
  query ListFootcareUitwasbareschoens(
    $filter: ModelFootcareUitwasbareschoenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFootcareUitwasbareschoens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        filename
        color
        bijzonderheden
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFootcareUitwasbareschoens = /* GraphQL */ `
  query SyncFootcareUitwasbareschoens(
    $filter: ModelFootcareUitwasbareschoenFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFootcareUitwasbareschoens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        filename
        color
        bijzonderheden
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFootcareSandaal = /* GraphQL */ `
  query GetFootcareSandaal($id: ID!) {
    getFootcareSandaal(id: $id) {
      id
      item
      filename
      color
      bijzonderheden
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFootcareSandaals = /* GraphQL */ `
  query ListFootcareSandaals(
    $filter: ModelFootcareSandaalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFootcareSandaals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        filename
        color
        bijzonderheden
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFootcareSandaals = /* GraphQL */ `
  query SyncFootcareSandaals(
    $filter: ModelFootcareSandaalFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFootcareSandaals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        filename
        color
        bijzonderheden
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFootcareClosingItem = /* GraphQL */ `
  query GetFootcareClosingItem($id: ID!) {
    getFootcareClosingItem(id: $id) {
      id
      item
      filename
      color
      size
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFootcareClosingItems = /* GraphQL */ `
  query ListFootcareClosingItems(
    $filter: ModelFootcareClosingItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFootcareClosingItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        filename
        color
        size
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFootcareClosingItems = /* GraphQL */ `
  query SyncFootcareClosingItems(
    $filter: ModelFootcareClosingItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFootcareClosingItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        filename
        color
        size
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFootcareModel = /* GraphQL */ `
  query GetDevFootcareModel($id: ID!) {
    getDevFootcareModel(id: $id) {
      id
      category
      modelnumber
      colornumber
      filename
      editors
      search
      attributes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFootcareModels = /* GraphQL */ `
  query ListDevFootcareModels(
    $filter: ModelDevFootcareModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFootcareModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        modelnumber
        colornumber
        filename
        editors
        search
        attributes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFootcareModels = /* GraphQL */ `
  query SyncDevFootcareModels(
    $filter: ModelDevFootcareModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFootcareModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        category
        modelnumber
        colornumber
        filename
        editors
        search
        attributes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFootcarePriceLevel = /* GraphQL */ `
  query GetDevFootcarePriceLevel($id: ID!) {
    getDevFootcarePriceLevel(id: $id) {
      id
      item
      healthcareProviders
      suppliers
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFootcarePriceLevels = /* GraphQL */ `
  query ListDevFootcarePriceLevels(
    $filter: ModelDevFootcarePriceLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFootcarePriceLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        healthcareProviders
        suppliers
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFootcarePriceLevels = /* GraphQL */ `
  query SyncDevFootcarePriceLevels(
    $filter: ModelDevFootcarePriceLevelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFootcarePriceLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        healthcareProviders
        suppliers
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFootcareSupplier = /* GraphQL */ `
  query GetDevFootcareSupplier($id: ID!) {
    getDevFootcareSupplier(id: $id) {
      id
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFootcareSuppliers = /* GraphQL */ `
  query ListDevFootcareSuppliers(
    $filter: ModelDevFootcareSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFootcareSuppliers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFootcareSuppliers = /* GraphQL */ `
  query SyncDevFootcareSuppliers(
    $filter: ModelDevFootcareSupplierFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFootcareSuppliers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFootcareHealthcareProvider = /* GraphQL */ `
  query GetDevFootcareHealthcareProvider($id: ID!) {
    getDevFootcareHealthcareProvider(id: $id) {
      id
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFootcareHealthcareProviders = /* GraphQL */ `
  query ListDevFootcareHealthcareProviders(
    $filter: ModelDevFootcareHealthcareProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFootcareHealthcareProviders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFootcareHealthcareProviders = /* GraphQL */ `
  query SyncDevFootcareHealthcareProviders(
    $filter: ModelDevFootcareHealthcareProviderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFootcareHealthcareProviders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFootcareStyle = /* GraphQL */ `
  query GetDevFootcareStyle($id: ID!) {
    getDevFootcareStyle(id: $id) {
      id
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFootcareStyles = /* GraphQL */ `
  query ListDevFootcareStyles(
    $filter: ModelDevFootcareStyleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFootcareStyles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFootcareStyles = /* GraphQL */ `
  query SyncDevFootcareStyles(
    $filter: ModelDevFootcareStyleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFootcareStyles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFootcareClosing = /* GraphQL */ `
  query GetDevFootcareClosing($id: ID!) {
    getDevFootcareClosing(id: $id) {
      id
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFootcareClosings = /* GraphQL */ `
  query ListDevFootcareClosings(
    $filter: ModelDevFootcareClosingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFootcareClosings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFootcareClosings = /* GraphQL */ `
  query SyncDevFootcareClosings(
    $filter: ModelDevFootcareClosingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFootcareClosings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFootcareInstep = /* GraphQL */ `
  query GetDevFootcareInstep($id: ID!) {
    getDevFootcareInstep(id: $id) {
      id
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFootcareInsteps = /* GraphQL */ `
  query ListDevFootcareInsteps(
    $filter: ModelDevFootcareInstepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFootcareInsteps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFootcareInsteps = /* GraphQL */ `
  query SyncDevFootcareInsteps(
    $filter: ModelDevFootcareInstepFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFootcareInsteps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevFootcareHeight = /* GraphQL */ `
  query GetDevFootcareHeight($id: ID!) {
    getDevFootcareHeight(id: $id) {
      id
      item
      editors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevFootcareHeights = /* GraphQL */ `
  query ListDevFootcareHeights(
    $filter: ModelDevFootcareHeightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevFootcareHeights(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevFootcareHeights = /* GraphQL */ `
  query SyncDevFootcareHeights(
    $filter: ModelDevFootcareHeightFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevFootcareHeights(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        item
        editors
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
