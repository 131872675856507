<script setup>
import {
  IonRefresher,
  IonRefresherContent,
  IonItem,
  IonTitle,
  IonList,
  IonHeader,
  IonButtons,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonContent,
  IonToolbar,
  menuController,
  IonAvatar,
  IonAccordion,
  IonAccordionGroup,
  IonMenu,
  IonRouterOutlet,
  IonApp,
  IonText,
  IonSegment,
  IonBadge,
  IonSegmentButton,
  IonSearchbar,
  alertController,
  useIonRouter,
} from "@ionic/vue";
import { version } from "../package.json";
import { useGlobalStore } from "./store/global";
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";

import { fetchUserAttributes } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";

import { DevFittrOrder } from "@/models";

import { ref } from "vue";

import { QSeparator, QSkeleton, useQuasar } from "quasar";

import { DataStore } from "aws-amplify/datastore";

const $q = useQuasar();
const store = useGlobalStore();
const router = useIonRouter();
const editButton = ref(false);
const orderListType = ref("history");
const disabled = ref(true);
const loading = ref(false);
const accordionGroup = ref();
const appClient = ref();
const firstRef = ref(true);
const scrollRef = ref(false);

const timeLeft = ref();

const date = new Date(Date.now());
const currentDate = ref(
  (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "-" +
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "-" +
    date.getFullYear() +
    " " +
    (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
    ":" +
    (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())
);
const orderDate = ref(currentDate);

async function currentAuthenticatedUser() {
  try {
    store.logged_in_user = await fetchUserAttributes();
    store.signedIn = true;
    if (store.logged_in_user['custom:organisation'].includes('Penders') || store.logged_in_user['custom:organisation'].includes('Kievit')) {
      store.organisation = 'penders';
    }
    if (store.logged_in_user['custom:organisation'].includes('Hanssen')) {
      store.organisation = 'hanssen';
    }
  } catch (err) {
    store.logged_in_user = undefined;
    store.signedIn = false;
    console.log("There is no logged in user");
  }
}

const reload = () => {
  window.location.reload();
};

const doRefresh = async (event) => {
  loading.value = false;
  console.log("Begin async operation");
  await store.getSubmittedOrders();
  await store.getArchivedOrders();
  setTimeout(() => {
    loading.value = true;
    event.target.complete();
    console.log("Async operation has ended");
  }, 1000);
};

const timeDiffCalc = (dateFuture, dateNow) => {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  let dagen;
  let dayhours = 0;
  if (days > 0) {
    dayhours = days * 24;
  }
  dagen = hours + dayhours;
  timeLeft.value = hours;
  return dagen;
};

const dateCompare = (d1, d2) => {
  const d = timeDiffCalc(new Date(d2), Date.now());
  editButton.value = d < 48;
  const limit = 48 - d;
  if (limit > 0) {
    return limit;
  } else {
    return "";
  }
};

const getOrders = async () => {
  if (store.logged_in_user) {
    store.submittedOrders = await store.getSubmittedOrders();
    store.archivedOrders = await store.getArchivedOrders();
    setTimeout(async () => {
      loading.value = true;
    }, 1000);
  }
};

const closeAccordion = () => {
  if (accordionGroup.value) {
    scrollRef.value = false;
    accordionGroup.value.$el.value = undefined;
  }
};

const deleteArchivedOrder = async (id) => {
  const alert = await alertController.create({
    cssClass: "my-custom-class",
    header: "Weet je zeker dat je de order verwijderen?",
    // subHeader: 'selecteer een bestemming voor verwerking van de order',
    translucent: true,
    buttons: [
      {
        text: "Afbreken",
        role: "cancel",
        cssClass: "secondary",
        handler: () => {
          console.log("Confirm Cancel");
        },
      },
      {
        text: "Verwijderen!",
        handler: async () => {
          const todelete = await DataStore.query(DevFittrOrder, id);
          await DataStore.delete(todelete);
          await store.getArchivedOrders();
        },
      },
    ],
  });
  return alert.present();
};

Hub.listen("auth", async ({ payload }) => {
  switch (payload.event) {
    case "signedIn":
      try {
        store.logged_in_user = await fetchUserAttributes();
        store.signedIn = true;

        if (store.logged_in_user['custom:organisation'].includes('Penders') || store.logged_in_user['custom:organisation'].includes('Kievit')) {
          store.organisation = 'penders';
        }
        if (store.logged_in_user['custom:organisation'].includes('Hanssen')) {
          store.organisation = 'hanssen';
        }

        $q.notify({
          color: "green-6",
          position: "center",
          icon: "fa-light fa-hand-horns",
          message: "Je bent ingelogd!",
        });

        await store.dbSync();
        await getOrders();

        closeAccordion();
      } catch (error) {
        console.log("user failed to sign in", error);
      }
      console.log("user have been signedIn successfully.");
      break;
    case "signedOut":
      store.logged_in_user = undefined;
      store.signedIn = false;
      store.organisation = 'fittr';

      $q.notify({
        color: "red-6",
        position: "center",
        icon: "fa-light fa-hand-sparkles",
        message: "Je bent uitgelogd!",
      });

      console.log("user have been signedOut successfully.");
      break;
    case "tokenRefresh":
      console.log("auth tokens have been refreshed.");
      break;
    case "tokenRefresh_failure":
      console.log("failure while refreshing auth tokens.");
      break;
    case "signInWithRedirect":
      console.log("signInWithRedirect API has successfully been resolved.");
      break;
    case "signInWithRedirect_failure":
      console.log("failure while trying to resolve signInWithRedirect API.");
      break;
    case "customOAuthState":
      logger.info("custom state returned from CognitoHosted UI");
      break;
  }
});
const onScrollFirst = (position) => {
  if (position.detail.currentY > 70) {
    scrollRef.value = true;
  } else if (position.detail.currentY < 70) {
    scrollRef.value = false;
  }
};

async function reorder(order) {
    store.activeOrder = await DataStore.query(
        DevFittrOrder,
        order.id
    );
    let temp;
    store.activeOrder.formData.colornumber
        ? (temp = await DataStore.query(
            store.Models,
            (c) =>
                c.and((c) => [
                  c.modelnumber.eq(
                      store.activeOrder.formData
                          ?.modelnumber
                  ),
                  c.colornumber.contains(
                      store.activeOrder?.formData
                          ?.colornumber
                  ),
                ])
        ))
        : (temp = await DataStore.query(
            store.Models,
            (c) =>
                c.modelnumber.eq(
                    store.activeOrder.formData?.modelnumber
                )
        ));
  if (!temp[0]) {
    $q.notify({
      color: "red-5",
      position: "center",
      textColor: "white",
      icon: "fas fa-triangle-exclamation",
      message:
          "Het lijkt erop dat dit model niet meer in de database staat. Kies een ander model of controleer of het model nog bestaat.",
    });
  }
  else {
    store.activeModel = temp[0];
    await menuController.close('menu');
    await router.push(
        '/osb/order/reorder_' + order.id
    );
  }
}
currentAuthenticatedUser();
</script>

<template>
  <ion-app>
    <ion-menu
      style="--background: #f7f7f7"
      :backdrop="true"
      :visible="true"
      side="end"
      @ionWillOpen="async () => await getOrders()"
      @ionDidClose="() => closeAccordion()"
      content-id="menu"
      menu-id="menu"
    >
      <ion-toolbar
        mode="ios"
        color="primary"
        style="
          position: absolute;
          top: 0;
          padding-top: var(--ion-safe-area-top);
        "
      >
        <ion-buttons mode="ios" slot="start">
          <ion-button @click="() => menuController.close()">Close</ion-button>
        </ion-buttons>
        <ion-title mode="ios" v-if="store.logged_in_user"
          >{{ "Hallo " + store.logged_in_user?.given_name }}!
        </ion-title>
        <ion-buttons
          mode="ios"
          v-if="store.logged_in_user"
          slot="end"
          style="padding-right: 20px"
        >
          <ion-button
            v-if="store.logged_in_user['custom:isAdmin']?.includes('true')"
            @click="
              () => {
                router.push('/admin');
                menuController.close();
              }
            "
            fill="solid"
            color="secondary"
          >
            <i
              style="font-size: 26px; color: rgba(255, 255, 255, 0.9)"
              class="fa-light fa-tools"
            ></i>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-header
        mode="ios"
        collapse="fade"
        :style="[
          scrollRef !== false ? 'z-index:600;opacity:1' : 'z-index:0;opacity:0',
        ]"
        style="border: none; top: 50px; transition: opacity 0.25s linear"
      >
        <ion-toolbar
          mode="ios"
          style="background: #f7f7f7; --border-style: hidden; padding-top: 0"
        >
          <ion-buttons mode="ios" style="width: 86%; margin-left: 7%">
            <ion-searchbar
              inputmode="search"
              debounce="1500"
              mode="ios"
              style="padding-bottom: 0; margin-top: 25px; background: #f7f7f7"
              @ionInput="store.searchSubmittedOrders($event.target.value)"
            ></ion-searchbar>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-list
        mode="ios"
        style="
          z-index: 50;
          max-height: 100vh;
          height: 100%;
          background: #f7f7f7;
        "
      >
        <ion-accordion-group
          ref="accordionGroup"
          expand="inset"
          style="z-index: 50; width: 90%; margin-left: 5%; margin-right: 5%"
        >
          <ion-accordion
            class="order"
            style="max-height: 95vh; background: #f7f7f7"
            v-if="store.logged_in_user"
            value="orders"
          >
            <ion-item
              mode="ios"
              :style="[
                scrollRef === false
                  ? 'z-index:500;opacity:1'
                  : 'z-index:0:opacity:0',
              ]"
              style="--background: #f7f7f7; transition: opacity 0.25s linear"
              ref="orderHistoryContainer"
              slot="header"
              button
              @click="async () => await getOrders()"
            >
              <ion-avatar
                slot="start"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <i
                  class="fa-light fa-clipboard-list"
                  style="font-size: 20px"
                ></i>
              </ion-avatar>
              <ion-label>Order menu</ion-label>
            </ion-item>

            <ion-list
              slot="content"
              style="min-height: 90vh; background: #f7f7f7"
            >
              <ion-content
                mode="ios"
                :scroll-events="true"
                @ionScroll="onScrollFirst"
                style="min-height: 90vh"
              >
                <ion-refresher
                  style="background: transparent"
                  slot="fixed"
                  @ionRefresh="doRefresh($event)"
                >
                  <ion-refresher-content
                    style="background: transparent"
                  ></ion-refresher-content>
                </ion-refresher>

                <ion-header
                  style="display: block"
                  mode="ios"
                  collapse="condense"
                >
                  <ion-toolbar mode="ios" style="--background: #f7f7f7">
                    <ion-buttons
                      mode="ios"
                      style="width: 100%; padding-top: 8px"
                    >
                      <ion-segment
                        mode="ios"
                        :value="orderListType"
                        style="width: 100%"
                      >
                        <ion-segment-button
                          mode="ios"
                          layout="icon-start"
                          value="history"
                          @click="orderListType = 'history'"
                        >
                          <i
                            class="fal fa-history"
                            style="margin-right: 5px"
                          ></i>
                          <ion-label>Order historie</ion-label>
                        </ion-segment-button>
                        <ion-segment-button
                          mode="ios"
                          v-if="!(store.archivedOrders.length > 0)"
                          disabled
                          layout="icon-start"
                          style="padding-left: 15px; padding-right: 15px"
                          value="archived"
                          @click="orderListType = 'archived'"
                        >
                          <i
                            class="fal fa-archive"
                            style="margin-right: 5px"
                          ></i>
                          <ion-label>Opgeslagen orders</ion-label>
                        </ion-segment-button>
                        <ion-segment-button
                          mode="ios"
                          v-if="store.archivedOrders.length > 0"
                          layout="icon-start"
                          style="padding-left: 5px; padding-right: 5px"
                          value="archived"
                          @click="orderListType = 'archived'"
                        >
                          <i
                            class="fal fa-archive"
                            style="margin-right: 5px"
                          ></i>
                          <ion-label>Opgeslagen orders</ion-label>
                          <ion-badge
                            color="danger"
                            style="
                              margin-inline-start: 5px;
                              color: white;
                              display: flex;
                              justify-content: center;
                            "
                          >
                            {{ store.archivedOrders.length }}
                          </ion-badge>
                        </ion-segment-button>
                      </ion-segment>
                    </ion-buttons>
                  </ion-toolbar>
                  <ion-toolbar mode="ios" style="--background: #f7f7f7">
                    <ion-buttons mode="ios">
                      <ion-searchbar
                        inputmode="search"
                        debounce="1500"
                        mode="ios"
                        style="margin-top: 10px; background: #f7f7f7"
                        @ionInput="
                          orderListType === 'archived'
                            ? store.searchArchivedOrders($event.target.value)
                            : store.searchSubmittedOrders($event.target.value)
                        "
                      ></ion-searchbar>
                    </ion-buttons>
                  </ion-toolbar>
                </ion-header>

                <ion-list
                  v-if="loading === true && orderListType === 'history'"
                  style="background: #f7f7f7; padding-bottom: 100px"
                >
                  <ion-card
                    v-for="order in store.submittedOrders"
                    :key="order.id"
                  >
                    <template v-if="order !== 'last_item'">
                      <!--                    {{ dateCompare(orderDate, order.updatedAt) }}-->
                      <!--                  {{ ((currentDate.substring(currentDate.length -5, currentDate.length -3)) - 3) > ((order.submittedAt.substring(order.submittedAt.length -5, order.submittedAt.length -3))) ? 'yess' : 'noooo'}}-->
                      <ion-card-header style="padding: 5px 20px 16px">
                        <!--                      <ion-chip-->
                        <!--                        v-if="order.status.includes('submit')"-->
                        <!--                        color="success"-->
                        <!--                        style="-->
                        <!--                          position: relative;-->
                        <!--                          left: 90%;-->
                        <!--                          &#45;&#45;color: var(&#45;&#45;ion-color-success);-->
                        <!--                        "-->
                        <!--                      >-->
                        <!--                        <i-->
                        <!--                          style="font-size: 24px"-->
                        <!--                          class="fas fa-clipboard-check"-->
                        <!--                        ></i>-->
                        <!--                      </ion-chip>-->
                        <!--                      <ion-chip-->
                        <!--                        v-else-->
                        <!--                        color="danger"-->
                        <!--                        style="-->
                        <!--                          position: relative;-->
                        <!--                          left: 85%;-->
                        <!--                          &#45;&#45;color: var(&#45;&#45;ion-color-success);-->
                        <!--                        "-->
                        <!--                      >-->
                        <!--                        <i class="fa-light fa-clipboard-list-check"></i>-->
                        <!--                      </ion-chip>-->
                        <ion-grid>
                          <ion-row>
                            <ion-col
                              size="4"
                              style="
                                padding: 0;
                                margin: 0;
                                display: flex;
                                align-items: end;
                              "
                            >
                              <ion-card-subtitle
                                style="width: 100%; font-weight: 800"
                              >
                                Kenmerk:
                              </ion-card-subtitle>
                            </ion-col>
                            <ion-col size="8" style="padding: 0; margin: 0">
                              <ion-card-title style="width: 100%">
                                <ion-label style="font-size: 0.6em"
                                  >{{
                                    order?.orderIdOne +
                                    " - " +
                                    order?.orderIdTwo
                                  }}
                                </ion-label>
                              </ion-card-title>
                            </ion-col>
                          </ion-row>
                          <q-separator style="margin-bottom: 10px" />
                          <ion-row>
                            <ion-col size="4" style="padding: 0; margin: 0">
                              <ion-card-subtitle
                                style="width: 100%; font-weight: 800"
                              >
                                Ingediend:
                              </ion-card-subtitle>
                            </ion-col>
                            <ion-col size="8" style="padding: 0; margin: 0">
                              <ion-card-subtitle style="width: 100%">
                                <ion-label>
                                  {{
                                    /*order.submittedAt + ' ' + (dateCompare(orderDate, order.updatedAt) !== '' ? '(Nog ' + dateCompare(orderDate, order.updatedAt) + ' uur te editen)' : '')*/
                                    order.submittedAt
                                  }}
                                </ion-label>
                              </ion-card-subtitle>
                            </ion-col>
                          </ion-row>
                          <q-separator style="margin-bottom: 10px" />
                          <ion-row>
                            <ion-col size="4" style="padding: 0; margin: 0">
                              <ion-card-subtitle
                                style="width: 100%; font-weight: 800"
                              >
                                Locatie:
                              </ion-card-subtitle>
                            </ion-col>
                            <ion-col size="8" style="padding: 0; margin: 0">
                              <ion-card-subtitle style="width: 100%">
                                {{
                                  order.order_destination ===
                                  "allard@cachet-it.nl"
                                    ? "test locatie"
                                    : order.order_destination
                                }}
                              </ion-card-subtitle>
                            </ion-col>
                          </ion-row>
                          <q-separator style="margin-bottom: 10px" />
                          <ion-row>
                            <ion-col size="4" style="padding: 0; margin: 0">
                              <ion-card-subtitle
                                style="width: 100%; font-weight: 800"
                              >
                                Status:
                              </ion-card-subtitle>
                            </ion-col>
                            <ion-col size="8" style="padding: 0; margin: 0">
                              <ion-card-subtitle style="width: 100%">
                                {{ order.status }}
                              </ion-card-subtitle>
                            </ion-col>
                          </ion-row>
                          <q-separator style="margin-bottom: 10px" />
                        </ion-grid>
                      </ion-card-header>
                      <ion-card-content
                        style="
                          margin-bottom: 15px;
                          display: flex;
                          justify-content: space-around;
                        "
                      >
                        <ion-button
                          size="small"
                          color="medium"
                          fill="solid"
                          style="--color: white"
                          @click="reorder(order)"
                        >
                          <ion-label style="color: white"
                            ><i
                              class="fa-light fa-clone"
                              style="margin-right: 5px"
                            ></i
                            >Nabestelling
                          </ion-label>
                        </ion-button>
                        <!--                      </template>-->
                        <ion-button
                          size="small"
                          color="primary"
                          fill="solid"
                          style="--color: white"
                          @click="
                            () => {
                              menuController.close('menu').then(() => {
                                router.push('/print-form/' + order.id);
                              });
                            }
                          "
                        >
                          <ion-label style="color: white"
                            ><i
                              class="fa-light fa-memo-pad"
                              style="margin-right: 5px"
                            ></i
                            >Formulier
                          </ion-label>
                        </ion-button>
                      </ion-card-content>
                    </template>
                    <template v-else>
                      <ion-card-header>
                        Deze lijst bevat maximaal de 20 meest recente orders. Voor oudere
                        bestellingen, gebruik de zoekfunctie.
                      </ion-card-header>
                    </template>
                  </ion-card>
                </ion-list>
                <!--                <ion-list v-else-if="store.state.submittedOrders?.length === 0 && orderListType === 'history'"-->
                <!--                          style="&#45;&#45;background:var(&#45;&#45;ion-color-tertiary);width:100%;">-->
                <!--                  <ion-label style="margin: 20px"><h1>Geen historie</h1></ion-label>-->
                <!--                </ion-list>-->
                <ion-list
                  v-else-if="
                    store.archivedOrders.length > 0 &&
                    orderListType === 'archived'
                  "
                  style="background: #f7f7f7; width: 100%; height: 100%"
                >
                  <ion-card
                    v-for="order in store.archivedOrders"
                    :key="order.id"
                  >
                    <ion-card-header style="padding: 5px 20px 16px">
                      <!--                      <ion-chip-->
                      <!--                        color="danger"-->
                      <!--                        style="-->
                      <!--                          position: relative;-->
                      <!--                          left: 85%;-->
                      <!--                          &#45;&#45;color: var(&#45;&#45;ion-color-success);-->
                      <!--                        "-->
                      <!--                      >-->
                      <!--                        <i-->
                      <!--                          style="font-size: 24px"-->
                      <!--                          class="fa-solid fa-poo-bolt"-->
                      <!--                        ></i>-->
                      <!--                      </ion-chip>-->

                      <ion-grid>
                        <ion-row>
                          <ion-col
                              size="4"
                              style="
                                padding: 0;
                                margin: 0;
                                display: flex;
                                align-items: end;
                              "
                          >
                            <ion-card-subtitle
                                style="width: 100%; font-weight: 800"
                            >
                              Kenmerk:
                            </ion-card-subtitle>
                          </ion-col>
                          <ion-col size="8" style="padding: 0; margin: 0">
                            <ion-card-title style="width: 100%">
                              <ion-label style="font-size: 0.6em"
                              >{{
                                  order?.orderIdOne +
                                  " - " +
                                  order?.orderIdTwo
                               }}
                              </ion-label>
                            </ion-card-title>
                          </ion-col>
                        </ion-row>
                        <q-separator style="margin-bottom: 10px" />
                        <ion-row>
                          <ion-col size="4" style="padding: 0; margin: 0">
                            <ion-card-subtitle
                                style="width: 100%; font-weight: 800"
                            >
                              Ingediend:
                            </ion-card-subtitle>
                          </ion-col>
                          <ion-col size="8" style="padding: 0; margin: 0">
                            <ion-card-subtitle style="width: 100%">
                              <ion-label>
                                {{
                                  /*order.submittedAt + ' ' + (dateCompare(orderDate, order.updatedAt) !== '' ? '(Nog ' + dateCompare(orderDate, order.updatedAt) + ' uur te editen)' : '')*/
                                  order.submittedAt
                                }}
                              </ion-label>
                            </ion-card-subtitle>
                          </ion-col>
                        </ion-row>
                        <q-separator style="margin-bottom: 10px" />
                        <ion-row>
                          <ion-col size="4" style="padding: 0; margin: 0">
                            <ion-card-subtitle
                                style="width: 100%; font-weight: 800"
                            >
                              Status:
                            </ion-card-subtitle>
                          </ion-col>
                          <ion-col size="8" style="padding: 0; margin: 0">
                            <ion-card-subtitle style="width: 100%">
                              {{ order.status }}
                            </ion-card-subtitle>
                          </ion-col>
                        </ion-row>
                        <q-separator style="margin-bottom: 10px" />
                      </ion-grid>
                    </ion-card-header>
                    <ion-card-content
                      style="
                        margin-bottom: 15px;
                        display: flex;
                        justify-content: space-around;
                      "
                    >
                      <ion-button
                        size="small"
                        color="danger"
                        fill="solid"
                        @click="
                          () => {
                            deleteArchivedOrder(order.id);
                          }
                        "
                      >
                        <ion-label style="color: white">
                          <i
                            class="fa-light fa-trash-can"
                            style="margin-right: 5px"
                          ></i>
                          Verwijder
                        </ion-label>
                      </ion-button>

                      <ion-button
                        size="small"
                        color="primary"
                        fill="solid"
                        style="--color: white"
                        @click="
                          async () => {
                            store.activeOrder = await DataStore.query(
                              DevFittrOrder,
                              order.id
                            );
                            let temp;
                            if (store.activeOrder.formData.colornumber) {
                              temp = await DataStore.query(store.Models, (c) =>
                                c.and((c) => [
                                  c.modelnumber.eq(
                                    store.activeOrder.formData?.modelnumber
                                  ),
                                  c.colornumber.contains(
                                    store.activeOrder?.formData?.colornumber
                                  ),
                                ])
                              );
                            } else {
                              temp = await DataStore.query(store.Models, (c) =>
                                c.modelnumber.eq(
                                  store.activeOrder.formData?.modelnumber
                                )
                              );
                            }
                            store.activeModel = temp[0];
                            await menuController.close('menu');
                            await router.push(
                              '/osb/order/archived_' + order.id
                            );
                          }
                        "
                      >
                        <ion-label style="color: white">
                          <i
                            class="fa-light fa-memo-circle-check"
                            style="margin-right: 5px"
                          ></i
                          >Verder
                        </ion-label>
                      </ion-button>
                    </ion-card-content>
                  </ion-card>
                </ion-list>

                <ion-list v-else style="background: #f7f7f7">
                  <ion-card v-for="item in 5" :key="item">
                    <ion-card-header style="padding: 5px 20px 16px">
                      <q-skeleton
                        animation="fade"
                        style="position: relative; left: 95%; top: 5px"
                        height="24px"
                        width="24px"
                        type="rect"
                      ></q-skeleton>

                      <ion-row style="margin-top: 10px">
                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="70%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-title>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              style="top: -2px"
                              width="60%"
                            ></q-skeleton>
                          </ion-card-title>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="80%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-title>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              style="top: -2px"
                              width="80%"
                            ></q-skeleton>
                          </ion-card-title>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="60%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="70%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="40%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col
                          size="6"
                          style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          "
                        >
                          <ion-card-subtitle>
                            <q-skeleton
                              animation="fade"
                              type="text"
                              width="60%"
                            ></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>
                      </ion-row>

                      <q-separator />
                    </ion-card-header>

                    <ion-card-content
                      style="
                        margin-bottom: 15px;
                        display: flex;
                        justify-content: space-around;
                      "
                    >
                      <q-skeleton
                        animation="fade"
                        type="QBtn"
                        height="40px"
                      ></q-skeleton>

                      <q-skeleton
                        animation="fade"
                        type="QBtn"
                        height="40px"
                      ></q-skeleton>
                    </ion-card-content>
                  </ion-card>
                </ion-list>
              </ion-content>
            </ion-list>
          </ion-accordion>

          <ion-accordion value="colors" style="background: #f7f7f7">
            <ion-item style="--background: #f7f7f7" slot="header" button>
              <ion-avatar
                slot="start"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <i
                  v-if="store.logged_in_user"
                  class="fa-light fa-user-check"
                  style="font-size: 20px"
                ></i>
                <i
                  v-else
                  class="fa-light fa-user-xmark"
                  style="font-size: 20px"
                ></i>
              </ion-avatar>
              <ion-label>Account</ion-label>
              <!--              <ion-label><p>{{ user.attributes.given_name + ' ' + user.attributes.family_name }}</p></ion-label>-->
            </ion-item>

            <ion-item
              slot="content"
              lines="none"
              style="
                --background: #f7f7f7;
                --padding-inline-start: 0;
                --padding-inline-end: 0;
                --padding-start: 0;
                padding: 20px 0 20px 0;
              "
            >
              <authenticator :login-mechanisms="['email']">
                <template v-slot="{ signOut }">
                  <div
                    style="
                      width: 100%;
                      padding-top: 10px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <ion-button
                      v-if="store.logged_in_user"
                      capitalize
                      color="danger"
                      size="lg"
                      @click="signOut"
                    >
                      <ion-text style="font-size: 16px">
                        <i
                          class="fa-light fa-sign-out"
                          style="font-size: 16px; margin-right: 5px"
                        ></i
                        >|
                      </ion-text>
                      <ion-text style="font-size: 16px; margin-left: 5px"
                        >Uitloggen
                      </ion-text>
                    </ion-button>
                  </div>
                </template>
              </authenticator>
            </ion-item>
          </ion-accordion>
          <!--            <ion-accordion value="language">-->
          <!--              <ion-item slot="header" lines="full">-->
          <!--                <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">-->
          <!--                  <i class="fa-light fa-map-marked" style="font-size: 20px"></i>-->
          <!--                </ion-avatar>-->
          <!--                <ion-label>{{ t('languageSettingsCaption') }}</ion-label>-->
          <!--                &lt;!&ndash;              <ion-label><p>{{ user.attributes.given_name + ' ' + user.attributes.family_name }}</p></ion-label>&ndash;&gt;-->
          <!--              </ion-item>-->

          <!--              <ion-list slot="content" style="width: 80%;margin-left: 10%;margin-bottom:10px;">-->
          <!--                <ion-item button lines="full" @click="setLanguage('nl')">-->
          <!--                  <ion-avatar slot="start"><img alt="" src="./assets/nl.png"></ion-avatar>-->
          <!--                  <ion-label>-->
          <!--                    Nederlands-->
          <!--                  </ion-label>-->
          <!--                  <i v-if="i18n.locale === 'nl'" slot="end" class="fal fa-check"></i>-->
          <!--                </ion-item>-->
          <!--                <ion-item button lines="full" @click="setLanguage('de')">-->
          <!--                  <ion-avatar slot="start"><img alt="nopic" src="./assets/de.png"></ion-avatar>-->
          <!--                  <ion-label>-->
          <!--                    Deutsch-->
          <!--                  </ion-label>-->
          <!--                  <i v-if="i18n.locale === 'de'" slot="end" class="fal fa-check"></i>-->
          <!--                </ion-item>-->
          <!--                <ion-item button lines="full" @click="setLanguage('en')">-->
          <!--                  <ion-avatar slot="start"><img alt="nopic" src="./assets/en.png"></ion-avatar>-->
          <!--                  <ion-label>-->
          <!--                    English-->
          <!--                  </ion-label>-->
          <!--                  <i v-if="i18n.locale === 'en'" slot="end" class="fal fa-check"></i>-->
          <!--                </ion-item>-->
          <!--              </ion-list>-->
          <!--            </ion-accordion>-->
          <ion-accordion
            style="border-bottom: solid 0.15px rgba(25, 25, 25, 0.25)"
            value="help"
          >
            <ion-item slot="header" style="--background: #f7f7f7" button>
              <ion-avatar
                slot="start"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <i
                  class="fa-light fa-comments-question-check"
                  style="font-size: 20px"
                ></i>
              </ion-avatar>
              <ion-label> Help</ion-label>
            </ion-item>

            <ion-list
              slot="content"
              style="width: 80%; margin-left: 10%; margin-bottom: 10px"
            >
              <ion-item button lines="full">
                <ion-avatar
                  slot="start"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <i
                    class="fal fa-arrow-circle-right"
                    style="font-size: 20px"
                  ></i>
                </ion-avatar>
                <ion-button @click="() => reload()">Restart</ion-button>
                <!--                <ion-button @click="() => insert()">Restart</ion-button>-->
              </ion-item>
              <ion-item button lines="full">
                <ion-avatar
                  slot="start"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <i class="fal fa-code-branch" style="font-size: 20px"></i>
                </ion-avatar>
                <ion-label> Version: {{version}}</ion-label>
              </ion-item>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>

        <!--          <div style="width:100%;height:140px;display:flex;justify-content:center;align-items:center">-->
        <!--            <ion-button v-if="user" capitalize color="warning" @click="() => { menuController.close('FtrSideMenu').then(() => {router.push('/order/custom')})}">-->
        <!--              <ion-text style="font-size: 16px"><i class="fa-light fa-pencil-ruler" style="font-size: 16px;margin-right:5px"></i>|</ion-text><ion-text style="font-size: 16px;margin-left:5px">{{ t('customOrderCaption') }}</ion-text>-->
        <!--            </ion-button>-->
        <!--          </div>-->
        <!--          <ModelPageFilter style="left:unset;height:auto;width:70%;" propName="brands" title="Merk"-->
        <!--                           :items="store.state.brands"-->
        <!--                           :loading="store.state.brands.length > 0"></ModelPageFilter>-->

        <!--          <ModelPageFilter style="left:unset;height:auto;width:70%;" propName="brands" title="Merk"-->
        <!--                           :items="store.state.brands"-->
        <!--                           :loading="store.state.brands.length > 0"></ModelPageFilter>-->
      </ion-list>
    </ion-menu>

    <ion-router-outlet id="menu" />
  </ion-app>
</template>
<style>
.amplify-field-group :not(:first-child) .amplify-input {
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
}

[data-amplify-authenticator] [data-amplify-container] {
  position: relative;
  place-self: center;
  width: 25rem !important;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
}

.amplify-field-group :not(:first-child) .amplify-input {
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
}

[data-amplify-authenticator] [data-amplify-container] {
  --width: var(--amplify-components-authenticator-container-width-max);
}
.amplify-heading {
  color: var(--ion-color-dark);
}
[data-amplify-authenticator] {
  --amplify-components-authenticator-container-width-max: 100%;
  --amplify-colors-background-primary: var(--ion-color-primary);
  --amplify-colors-background-secondary: var(--ion-color-secondary);
  --amplify-colors-background-tertiary: var(--ion-color-tertiary);
  --amplify-colors-border-primary: transparent;
  --amplify-colors-border-secondary: transparent;
  --amplify-colors-border-tertiary: transparent;
  --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
  --amplify-colors-brand-primary-20: var(--amplify-colors-teal-90);
  --amplify-colors-brand-primary-40: var(--amplify-colors-teal-80);
  --amplify-colors-brand-primary-60: var(--amplify-colors-teal-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
  --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
  --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
  --amplify-colors-font-interactive: var(--amplify-colors-teal-80);
  --amplify-components-heading-color: var(--amplify-colors-neutral-20);
  --amplify-components-tabs-item-active-border-color: var(--ion-color-primary);
  --amplify-components-tabs-item-active-color: var(--ion-color-tertiary);
  --amplify-components-tabs-item-color: var(--ion-color-primary);
  --amplify-components-tabs-item-focus-color: var(--ion-color-primary);
  --amplify-components-text-color: var(--amplify-colors-font-interactive);
}

[data-amplify-authenticator] [data-state="inactive"] {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-dark);
  opacity: 0.5;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  background-color: transparent;
}
</style>
<style scoped>
ion-menu {
  --width: 100%;
}

ion-menu ion-content {
  --width: 100%;
}

ion-menu ion-card {
  width: 90%;
  margin-left: 5%;
  box-sizing: border-box;
}

ion-menu ion-card-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

ion-menu ion-content ion-content {
  padding-top: 0;
}

ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
  --color: var(--ion-color-primary);
}

ion-accordion {
  margin: 0 auto;
}

ion-accordion.accordion-expanding,
ion-accordion.accordion-expanded {
  width: calc(90% + 6%);
  margin: 2% auto;
}

@media (min-width: 350px) {
}

/*###############################################################*/
@media (min-width: 576px) {
}

/*###############################################################*/
@media (min-width: 768px) {
  ion-menu {
    --width: 29rem;
  }

  ion-menu ion-content {
    --width: 29rem;
  }
}

/*###############################################################*/
@media (min-width: 992px) {
}

/*###############################################################*/
@media (min-width: 1024px) {
}

/*###############################################################*/
@media (min-width: 1150px) {
}
</style>
