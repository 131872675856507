<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import {
  alertController,
  IonBackButton,
  IonButtons,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonToolbar,
  useIonRouter,
  IonBadge,
} from "@ionic/vue";

import {
  QBtn,
  QBtnGroup,
  QLayout,
  QPage,
  QPageSticky,
  QTable,
  QTd,
  QTh,
  QTr,
  useQuasar,
  copyToClipboard,
} from "quasar";

import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { DataStore, SortDirection } from "aws-amplify/datastore";
import { DevFootcareModel, DevModelCollection } from "@/models";
import { useGlobalStore } from "@/store/global";
import { Share } from "@capacitor/share";
import { uploadData } from 'aws-amplify/storage';

const $q = useQuasar();
const store = useGlobalStore();
const route = useRoute();
const submitResult = ref([]);
const router = useRouter();
const ionRouter = useIonRouter();
const fabPos = ref();
const draggingFab = ref(false);
const lastsLeft = ref([]);
const lastsRight = ref([]);
const footData = ref([]);
const rowsLeft = ref([]);
const rowsRight = ref([]);
const selected = ref([]);
const selectedLeft = ref([]);
const selectedRight = ref([]);
const leftTable = ref(null);
const rightTable = ref(null);
const dbOsbModel = store.Models;
const dbOsaModel = DevFootcareModel;
const dbModel = ref();
const submitting = ref(false);

let category;

const moveFab = (ev) => {
  if (
    ev.position.top > 150 &&
    ev.position.left > 20 &&
    ev.position.left < window.innerWidth - 120
  ) {
    draggingFab.value = ev.isFirst !== true && ev.isFinal !== true;
    fabPos.value = [fabPos.value[0] - ev.delta.x, fabPos.value[1] - ev.delta.y];
    localStorage.setItem("fabPosLastPage", JSON.stringify(fabPos.value));
  } else {
    console.log("too high");
  }
};

if ("fabPosLastPage" in localStorage) {
  fabPos.value = JSON.parse(localStorage.getItem("fabPosLastPage"));
} else {
  fabPos.value = [28, 28];
}

let filter1;
let filter2;
let contentType;
let columns;

// lastsLeft.value = [];
lastsLeft.value = store.lastProposals.lastsLeft;

// lastsRight.value = [];
lastsRight.value = store.lastProposals.lastsRight;

footData.value = store.lastProposals.footData;

store.footData = footData.value;

for (let i = 0; i < store.lastProposals?.lastsLeft?.length; i++) {
  const item = store.lastProposals.lastsLeft[i];
  const entered_footlength_left = store.lastProposals.footData.footlength_left;
  const entered_ballsize_left = store.lastProposals.footData.ballsize_left;
  const entered_insole_left = store.lastProposals.footData.insole_left;
  const oversize_left = store.lastProposals.footData.oversize_left;
  const entered_footlength_combined_left =
    store.lastProposals.footData.footlength_left +
    store.lastProposals.footData.oversize_left;

  const row = {
    ballsize:
      parseInt(item.ballsize) -
      parseInt(store.lastProposals.footData.ballsize_incl_insole_left),
    ballwidth:
      parseInt(item.ballwidth) -
      parseInt(store.lastProposals.footData.ballwidth_left),
    ballsize_last: item.ballsize,
    ballwidth_last: parseInt(item.ballwidth),
    brand: item.brand,
    name: item.lastgroup,
    ballsize_incl_insole:
      store.lastProposals.footData.ballsize_incl_insole_left,
    calculate_default_insole: item.calculate_default_insole,
    heelinstepsize: item.heelinstepsize,
    id: "left_" + item.id,
    lastgroup: item.lastgroup,
    lastlength: item.lastlength,
    lasttype: item.lasttype,
    size: item.shoesize,
    supplier: item.supplier,
    targetgroup: item.targetgroup,
    entered_footlength: entered_footlength_left,
    oversize: oversize_left,
    entered_footlength_combined: entered_footlength_combined_left,
    entered_ballsize: entered_ballsize_left,
    entered_insole: entered_insole_left,
    selected: false,
  };
  rowsLeft.value.push(row);
}

for (let i = 0; i < store.lastProposals.lastsRight.length; i++) {
  const item = store.lastProposals.lastsRight[i];
  const entered_footlength = store.lastProposals.footData.footlength_right;
  const entered_ballsize = store.lastProposals.footData.ballsize_right;
  const entered_insole = store.lastProposals.footData.insole_right;
  const oversize = store.lastProposals.footData.oversize_right;
  const entered_footlength_combined =
    store.lastProposals.footData.footlength_right +
    store.lastProposals.footData.oversize_right;
  const row = {
    ballsize:
      parseInt(item.ballsize) -
      parseInt(store.lastProposals.footData.ballsize_incl_insole_right),
    ballwidth:
      parseInt(item.ballwidth) -
      parseInt(store.lastProposals.footData.ballwidth_right),
    ballsize_last: item.ballsize,
    ballwidth_last: parseInt(item.ballwidth),
    brand: item.brand,
    name: item.lastgroup,
    ballsize_incl_insole:
      store.lastProposals.footData.ballsize_incl_insole_right,
    calculate_default_insole: item.calculate_default_insole,
    heelinstepsize: item.heelinstepsize,
    id: "right_" + item.id,
    lastgroup: item.lastgroup,
    lastlength: item.lastlength,
    lasttype: item.lasttype,
    size: item.shoesize,
    supplier: item.supplier,
    targetgroup: item.targetgroup,
    entered_footlength: entered_footlength,
    oversize: oversize,
    entered_footlength_combined: entered_footlength_combined,
    entered_ballsize: entered_ballsize,
    entered_insole: entered_insole,
    selected: false,
  };
  rowsRight.value.push(row);

  if (route.path.includes("osa") && route.path.includes("last")) {
    dbModel.value = dbOsaModel;
    contentType = "osa";
    category = "category";
    filter1 = "modelnumber";
    filter2 = "colornumber";
  } else if (route.path.includes("osb") && route.path.includes("last")) {
    dbModel.value = dbOsbModel;
    contentType = "osb";
    category = "category";
    filter1 = "brand";
    filter2 = "modelnumber";
  }
}
const styles = ref([]);
const closings = ref([]);
const colors = ref([]);
const heights = ref([]);
const insteps = ref([]);
const priceLevels = ref([]);
const healthcareProviders = ref([]);
const suppliers = ref([]);
const brands = ref([]);

rowsLeft.value.sort(function (a, b) {
  return Math.abs(0 - a.ballsize) - Math.abs(0 - b.ballsize);
});
const tempRows = rowsRight.value;
rowsRight.value = [];
rowsLeft.value.forEach((row) => {
  tempRows.forEach((temp) => {
    if (temp.lastgroup === row.lastgroup) {
      rowsRight.value.push(temp);
    }
  });
});

async function getSelectedString() {
  const lastgroups = [];

  for (let i = 0; i < selected.value.length; i++) {
    const el = selected.value[i];
    lastgroups.push(el);
  }

  const urlModels = [];
  const left = rowsLeft.value.filter((last) => last.selected === true);
  const right = rowsRight.value.filter((last) => last.selected === true);

  for (let i = 0; i < lastgroups.length; i++) {
    const lastgroup = lastgroups[i];
    console.log(lastgroup);
    // const temp = await DataStore.query(DevFittrModel, c => c.lastgroup("eq", lastgroup));
    const temp = await DataStore.query(
      store.Models,
      (c) =>
        c.and((c) => [
          c.lastgroup.eq(lastgroup.lastgroup),
          c.lasttypes.contains(lastgroup.lasttype.substring(0, 8)),
          c.sizerangeMin.le(lastgroup.size),
          c.sizerangeMax.ge(lastgroup.size),
          c[store.organisation].eq(true)
        ]),
      {
        sort: (s) =>
          s
            .modelnumber(SortDirection.DESCENDING)
            .colornumber(SortDirection.DESCENDING),
      }
    );
    const last_left = left.find((le) => le.lastgroup === lastgroup.lastgroup);
    const last_right = right.find((re) => re.lastgroup === lastgroup.lastgroup);

    for (let i1 = 0; i1 < temp.length; i1++) {
      let model = temp[i1];
      const modelObj = {
        modelID: model.id,
        id: model.id,
        category: model.category,
        modelnumber: model.modelnumber,
        modelname: model.modelname,
        colorname: model.colorname,
        filename: model.filename,
        lastgroup: model.lastgroup,
        brand: model.brand,
        attributes: model.attributes,
        colornumber: model.colornumber,
        lasttypes: model.lasttypes,
        lasttype_left: last_left.lasttype,
        lasttype_right: last_right.lasttype,
        size_left: last_left.size,
        size_right: last_right.size,
        sizerangeMin: model.sizerangeMin,
        sizerangeMax: model.sizerangeMax,
        prices: model.prices,
      };
      urlModels.push(modelObj);
    }
  }
  console.log(urlModels);
  return urlModels;
}

columns = [
  {
    name: "name",
    required: true,
    label: "Id",
    align: "left",
    field: (row) => row.name,
    format: (val) => `${val}`,
  },
  { name: "brand", align: "left", label: "Merk", field: "brand" },
  { name: "lasttype", align: "left", label: "Leesttype", field: "lasttype" },
  { name: "lastgroup", align: "left", label: "Leestgroup", field: "lastgroup" },
  { name: "size", align: "left", label: "Maat", field: "size" },
  {
    name: "ballsize",
    align: "left",
    label: "Balomvang",
    field: "ballsize",
    sortable: true,
  },
];

if (
  store.lastProposals.footData.ballwidth_left !== null &&
  !isNaN(store.lastProposals.footData.ballwidth_left)
) {
  columns.push({
    name: "ballwidth",
    align: "left",
    label: "Balwijdte",
    field: "ballwidth",
    sortable: true,
  });
}

async function goSelectedModels() {
  if (selected.value.length > 0) {
    submitting.value = true;
    const urlModels = await getSelectedString();

    const collection = await DataStore.save(
      new DevModelCollection({
        // models: JSON.stringify(urlModels),
        activeModels: null,
        footData: null,
        isLink: null,
        category: null,
        lastgroup: null,
      })
    );

    let params = {
      category: store.category,
      collectionId: collection.id,
    };
    console.log(urlModels);
    store.models = [];
    store.initialisedModels = [];
    store.cheapModels = [];
    store.expensiveModels = [];
    store.imageLoadingState = false;
    store.filterLoadingState = false;
    store.modelCollection = [];
    store.modelCollection = urlModels;
    await router.push("/osb/models/" + JSON.stringify(params));
    submitting.value = false;
  } else {
    $q.notify({
      color: "red-5",
      position: "center",
      textColor: "white",
      icon: "fas fa-triangle-exclamation",
      message: "Selecteer in de tabel minimaal één leest!",
    });
  }
}

const confirm = ref(false);

async function shareSelectedModels() {
  submitting.value = true;
  if (selected.value.length > 0) {
    const urlModels = await getSelectedString();
    const id = Date.now();
    try {
      const result = await uploadData({
        key: id+'.json',
        data: JSON.stringify(urlModels),
        options: {
          accessLevel: 'guest', // defaults to `guest` but can be 'private' | 'protected' | 'guest'
          //onProgress // Optional progress callback.
        }
      }).result;
      console.log('Succeeded: ', result);
    } catch (error) {
      submitting.value = false;
      console.log('Error : ', error);
    }
    const collection = await DataStore.save(
      new DevModelCollection({
        models: id+'.json',
        activeModels: null,
        footData: null,
        isLink: "true",
        category: null,
        lastgroup: null,
      })
    );
    let params = {
      category: store.category,
      collectionId: collection.id,
      isLink: true,
    };
    let title = ``;
    if (store.logged_in_user["custom:organisation"].includes("Hanssen")) {
      title =
        "Footcare heeft een aangepaste selectie orthopedisch schoeisel met u gedeeld.";
    } else {
      title =
        "Fittr heeft een aangepaste selectie orthopedisch schoeisel met u gedeeld.";
    }
    try {
      await Share.share({
        title: title,
        text: "Dit overzicht is speciaal voor u samengesteld en verzonden met de Fittr app!",
        url: store.shareUrl + JSON.stringify(params),
        dialogTitle: "Kies een app!",
      });
    } catch (e) {
      async function presentAlertConfirm() {
        const alert = await alertController.create({
          header: "Delen is helaas niet beschikbaar in deze browser!",
          message:
            "Wilt u de link naar het modeloverzicht naar het klembord kopieren?",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              id: "cancel-button",
              handler: (blah) => {
                console.log("Confirm Cancel:", blah);
              },
            },
            {
              text: "Ja",
              id: "confirm-button",
              handler: async () => {
                console.log("Confirm Okay");

                copyToClipboard(store.shareUrl + JSON.stringify(params))
                  .then(() => {
                    // success!
                  })
                  .catch(() => {
                    // fail
                  });
              },
            },
          ],
        });
        return alert.present();
      }

      await presentAlertConfirm();
      submitting.value = false;
    }
    submitting.value = false;
    return store.shareUrl + JSON.stringify(params);
  } else {
    $q.notify({
      color: "red-5",
      position: "center",
      textColor: "white",
      icon: "fas fa-triangle-exclamation",
      message: "Selecteer in de tabel minimaal één leest!",
    });
  }
}

const lastIndex = ref(null);

function onSelection({ rows, added, evt }) {
  if (rows[0].id.includes("left")) {
    for (let i = 0; i < rowsLeft.value.length; i++) {
      if (rowsLeft.value[i].id === rows[0].id) {
        rowsLeft.value[i].selected = added;
        const right = rowsRight.value.find(
          (row) => row.lastgroup === rowsLeft.value[i].lastgroup
        );
        right.selected = added;
      }
    }
  } else if (rows[0].id.includes("right")) {
    for (let i = 0; i < rowsRight.value.length; i++) {
      if (rowsRight.value[i].id === rows[0].id) {
        rowsRight.value[i].selected = added;
        const left = rowsLeft.value.find(
          (row) => row.lastgroup === rowsRight.value[i].lastgroup
        );
        left.selected = added;
      }
    }
  }

  if (rows.length === 0 || leftTable.value === void 0) {
    return;
  }

  const row = rows[0];
  const filteredSortedRows = leftTable.value.filteredSortedRows;
  const rowIndex = filteredSortedRows.indexOf(row);
  const localLastIndex = leftTable.value;

  lastIndex.value = rowIndex;

  document.getSelection().removeAllRanges();

  if ($q.platform.is.mobile === true) {
    evt = { ctrlKey: true };
  } else if (
    evt !== Object(evt) ||
    (evt.shiftKey !== true && evt.ctrlKey !== true)
  ) {
    selected.value = added === true ? rows : [];
    return;
  }

  const operateSelection =
    added === true
      ? (selRow) => {
          const selectedIndex = selected.value.indexOf(selRow);
          if (selectedIndex === -1) {
            selected.value = selected.value.concat(selRow);
          }
        }
      : (selRow) => {
          const selectedIndex = selected.value.indexOf(selRow);
          if (selectedIndex > -1) {
            selected.value = selected.value
              .slice(0, selectedIndex)
              .concat(selected.value.slice(selectedIndex + 1));
          }
        };

  if (localLastIndex === null || evt.shiftKey !== true) {
    operateSelection(row);
    return;
  }

  const from = localLastIndex < rowIndex ? localLastIndex : rowIndex;
  const to = localLastIndex < rowIndex ? rowIndex : localLastIndex;
  for (let i = from; i <= to; i += 1) {
    operateSelection(filteredSortedRows[i]);
  }
}
</script>

<template>
  <ion-page ref="root">
    <ion-header :translucent="true">
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button
            style="color: var(--ion-color-light)"
            default-href="/home"
          ></ion-back-button>
          <!--          <img-->
          <!--            @click="() => ionRouter.navigate('/home', 'back', 'push')"-->
          <!--            class="header-logo"-->
          <!--            alt="no-image"-->
          <!--            src="../assets/logo.png"-->
          <!--          />-->
          <img
            @click="() => ionRouter.navigate('/home', 'back', 'push')"
            class="header-logo"
            alt="no-image"
            :src="store.app_logo"
          />
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button style="color: var(--ion-color-light)">
            <ion-menu-toggle
              style="
                z-index: 200;
                position: absolute;
                width: 100px;
                height: 100%;
              "
            ></ion-menu-toggle>
            <i style="font-size: 24px" class="fa-regular fa-bars"></i>
            <ion-badge
              color="secondary"
              style="
                display: none;
                z-index: 100;
                position: fixed;
                right: 0;
                top: 0;
              "
              :style="
                !store.logged_in_user ? 'display: block' : 'display: none'
              "
            >
              <i class="fa-regular fa-user-xmark"></i
            ></ion-badge>
            <ion-menu-toggle
              style="position: absolute; width: 100%; height: 100%"
            ></ion-menu-toggle>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen color="light">
      <q-layout style="width: 100%; height: 100%">
        <q-page-container>
          <q-page>
            <ion-grid
              style="
                height: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-left: 0;
                margin-right: 0;
                width: 100%;
              "
            >
              <ion-row
                style="
                  overflow-y: auto;
                  padding-top: calc(10px + var(--ion-safe-area-top, 0));
                "
              >
                <ion-col size-xs="12" size-sm="6">
                  <ion-card
                    class="custom-card"
                    style="padding-left: 10px; padding-bottom: 20px"
                  >
                    <!--                    <ion-card-header style="position: relative;left: -16px; width: 100%">-->
                    <!--                      <ion-label style="font-size: 24px">Links</ion-label>-->
                    <!--                      <ion-button><i class="fal fa-share-from-square"></i></ion-button>-->
                    <!--                    </ion-card-header>-->
                    <ion-card-content style="padding: 16px 0 16px 0">
                      <ion-item style="position: relative; left: -16px">
                        <ion-card-title>
                          <ion-label color="tertiary" style="font-size: 24px"
                            >Links
                          </ion-label>
                        </ion-card-title>
                      </ion-item>
                      <q-table
                        style="left: 3px; width: 96%; margin-top: 6px"
                        :hide-bottom="true"
                        bordered
                        ref="leftTable"
                        :rows="rowsLeft"
                        wrap-cells
                        flat
                        :columns="columns"
                        row-key="name"
                        :visible-columns="[
                          'brand',
                          'lasttype',
                          'ballsize',
                          'size',
                          'ballwidth',
                        ]"
                        :pagination="{ rowsPerPage: 20 }"
                        :selected-rows-label="getSelectedString"
                        selection="multiple"
                        :selected="selected"
                        @selection="onSelection"
                        v-model:selected="selected"
                      >
                        <template v-slot:header="props">
                          <q-tr :props="props">
                            <!--                          <q-th  style="background-color: var(&#45;&#45;ion-color-tertiary-shade);color: var(&#45;&#45;ion-color-primary-contrast)"></q-th>-->
                            <q-th
                              style="
                                background-color: var(--ion-color-secondary);
                                color: white;
                              "
                              v-for="col in props.cols"
                              :key="col.name"
                              :props="props"
                              :style="col.name === 'name' ? 'display:none' : ''"
                            >
                              {{ col.label }}
                            </q-th>
                            <q-th
                              style="
                                background-color: var(--ion-color-tertiary);
                                color: white;
                              "
                            >
                              <i class="fa-solid fa-info"></i
                            ></q-th>
                          </q-tr>
                        </template>
                        {{ l }}
                        <template v-slot:body="props">
                          <q-tr
                            :props="props"
                            @click="
                              props.selected === true
                                ? (props.selected = false)
                                : (props.selected = true)
                            "
                            :id="props.row.lastgroup"
                          >
                            <!--                          <q-td>-->
                            <!--                            <q-checkbox v-model="props.selected"/>-->
                            <!--                          </q-td>-->
                            <template v-for="col in props.cols" :key="col.name">
                              <q-td
                                v-if="col.name === 'ballwidth'"
                                :style="[
                                  col.name === 'ballwidth' &&
                                  col.value < 2 &&
                                  col.value > -12
                                    ? 'background-color:rgba(0, 155, 0, 0.5);color:white'
                                    : 'background-color:rgba(155, 0, 0, 0.5);color:white',
                                  col.name !== 'ballwidth'
                                    ? 'background-color:transparent;color:var(--ion-color-dark)'
                                    : 'background-color:;color:',
                                  col.name === 'name' ? 'display:none' : '',
                                ]"
                              >
                                {{ col.value }}
                              </q-td>
                              <q-td
                                v-else
                                :style="[
                                  col.name === 'ballsize' &&
                                  col.value < 3 &&
                                  col.value > -5
                                    ? 'background-color:rgba(0, 155, 0, 0.5);color:white'
                                    : 'background-color:rgba(155, 0, 0, 0.5);color:white',
                                  col.name !== 'ballsize'
                                    ? 'background-color:transparent;color:var(--ion-color-dark)'
                                    : 'background-color:;color:',
                                  col.name === 'name' ? 'display:none' : '',
                                ]"
                              >
                                {{ col.value }}
                              </q-td>
                            </template>
                            <q-td>
                              <q-btn
                                size="xs"
                                color="info"
                                round
                                dense
                                @click="
                                  $event.stopPropagation();
                                  props.expand = !props.expand;
                                "
                                :icon="
                                  props.expand ? 'fal fa-minus' : 'fal fa-plus'
                                "
                              />
                            </q-td>
                          </q-tr>
                          <q-tr
                            v-if="props.expand"
                            :props="props"
                            style="
                              box-shadow: inset 0 2px 5px 0
                                rgba(200, 200, 200, 1);
                            "
                          >
                            <q-td
                              :colspan="footData.ballwidth_right ? 6 : 5"
                              style="padding: 1px"
                            >
                              <table
                                style="
                                  width: 96%;
                                  margin-bottom: 10px;
                                  margin-top: 10px;
                                  margin-left: 2%;
                                "
                              >
                                <tr style="padding: 1px">
                                  <q-th></q-th>
                                  <q-th
                                    style="
                                      border-bottom: 1px rgba(155, 155, 155, 1)
                                        solid;
                                    "
                                    >Ingevoerd
                                  </q-th>
                                  <q-th
                                    style="
                                      background-color: rgba(
                                        230,
                                        230,
                                        230,
                                        0.5
                                      );
                                      border-bottom: 1px rgba(155, 155, 155, 1)
                                        solid;
                                    "
                                  >
                                    Leest
                                    <q-btn
                                      style="
                                        position: relative;
                                        left: 5px;
                                        top: -5px;
                                      "
                                      round
                                      flat
                                      icon="fal fa-info-square"
                                      size="xs"
                                    >
                                      <q-tooltip
                                        max-width="50%"
                                        style="font-size: 16px"
                                      >
                                        De afgeronde waarde van de door de
                                        leverancier gehanteerde waarde zoals
                                        weergegeven in de leestabel behorende
                                        bij dit leesttype
                                      </q-tooltip>
                                    </q-btn>
                                  </q-th>
                                  <q-th
                                    style="
                                      border-bottom: 1px rgba(155, 155, 155, 1)
                                        solid;
                                    "
                                    >Verschil
                                  </q-th>
                                </tr>
                                <tr style="padding: 1px">
                                  <q-td>Lengte</q-td>
                                  <q-td style="text-align: center"
                                    ><p style="display: inline-flex; margin: 0">
                                      {{
                                        footData.footlength_left +
                                        " + " +
                                        footData.oversize_left +
                                        " = "
                                      }}
                                    </p>
                                    <p
                                      style="
                                        display: inline-flex;
                                        font-weight: bold;
                                        margin: 0 0 0 5px;
                                        font-size: 1.1em;
                                      "
                                    >
                                      {{
                                        parseInt(
                                          footData.footlength_incl_oversize_left
                                        )
                                      }}
                                    </p>
                                    <q-btn
                                      style="
                                        position: relative;
                                        left: 5px;
                                        top: -5px;
                                      "
                                      round
                                      flat
                                      icon="fal fa-info-square"
                                      size="xs"
                                    >
                                      <q-tooltip
                                        max-width="50%"
                                        style="font-size: 16px"
                                      >
                                        Het vetgedrukte is de totaal optelling
                                        van de ingevoerde voetlengte en de
                                        ingestelde overmaat
                                      </q-tooltip>
                                    </q-btn>
                                  </q-td>
                                  <q-td
                                    style="
                                      background-color: rgba(
                                        230,
                                        230,
                                        230,
                                        0.5
                                      );
                                      text-align: center;
                                    "
                                    ><p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{ parseInt(props.row.lastlength) }}
                                    </p>
                                  </q-td>
                                  <!--                                <q-td>Ingevoerd:</q-td>-->
                                  <q-td style="text-align: center">
                                    <p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{
                                        parseInt(
                                          props.row.entered_footlength_combined
                                        ) -
                                        parseInt(props.row.lastlength) +
                                        " mm"
                                      }}
                                      <q-btn
                                        style="
                                          position: relative;
                                          left: 5px;
                                          top: -5px;
                                        "
                                        round
                                        flat
                                        icon="fal fa-info-square"
                                        size="xs"
                                      >
                                        <q-tooltip
                                          max-width="50%"
                                          style="font-size: 16px"
                                        >
                                          De schoenmaat weergegeven in de rij
                                          direct boven deze tekst, is de
                                          schoenmaat die het dichst bij de
                                          ingevoerde voetlengte aansluit volgens
                                          de door de leverancier opgegeven
                                          leestlengte
                                        </q-tooltip>
                                      </q-btn>
                                    </p>
                                  </q-td>
                                </tr>
                                <tr style="padding: 1px">
                                  <q-td>Balomvang</q-td>
                                  <q-td style="text-align: center"
                                    ><p style="display: inline-flex; margin: 0">
                                      {{
                                        footData.ballsize_left +
                                        " + (" +
                                        footData.insole_left +
                                        "*2) = "
                                      }}
                                    </p>
                                    <p
                                      style="
                                        display: inline-flex;
                                        font-weight: bold;
                                        margin: 0 0 0 5px;
                                        font-size: 1.1em;
                                      "
                                    >
                                      {{
                                        parseInt(
                                          footData.ballsize_incl_insole_left
                                        )
                                      }}
                                    </p>
                                    <q-btn
                                      style="
                                        position: relative;
                                        left: 5px;
                                        top: -5px;
                                      "
                                      round
                                      flat
                                      icon="fal fa-info-square"
                                      size="xs"
                                    >
                                      <q-tooltip
                                        max-width="50%"
                                        style="font-size: 16px"
                                      >
                                        Het vetgedrukte is de totaal optelling
                                        van de ingevoerde balmaat plus de
                                        ingevoerde voetbeddikte (voetbeddikte
                                        vermenigvuldigd met 2 geeft het volume
                                        van het voetbed weer)
                                      </q-tooltip>
                                    </q-btn>
                                  </q-td>
                                  <q-td
                                    style="
                                      background-color: rgba(
                                        230,
                                        230,
                                        230,
                                        0.5
                                      );
                                      text-align: center;
                                    "
                                    ><p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{ parseInt(props.row.ballsize_last) }}
                                    </p>
                                  </q-td>
                                  <!--                                <q-td>Ingevoerd:</q-td>-->
                                  <q-td style="text-align: center">
                                    <p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{
                                        parseInt(
                                          props.row.ballsize_incl_insole
                                        ) -
                                        parseInt(props.row.ballsize_last) +
                                        " mm"
                                      }}
                                      <q-btn
                                        style="
                                          position: relative;
                                          left: 5px;
                                          top: -5px;
                                        "
                                        round
                                        flat
                                        icon="fal fa-info-square"
                                        size="xs"
                                      >
                                        <q-tooltip
                                          max-width="50%"
                                          style="font-size: 16px"
                                        >
                                          Het verschil in millimeters tussen de
                                          ingevoerde waarde en de waarde als
                                          weergegeven in de door de leverancier
                                          gehanteerde leesttabel.
                                          <br />Voor elk weergegeven leesttype
                                          wordt deze waarde in de betreffende
                                          rij weergegeven in groen of rood
                                          gearceerd. Groen geeft aan dat het
                                          verschil binnen een bovenmarge van 3
                                          millimeter (ruimer) of een ondermarge
                                          van -5 millimeter (krapper) valt. Een
                                          roodgekleurde weergave geeft aan dat
                                          deze buiten deze marges valt.
                                          <br /><br /><strong>Let op!!</strong
                                          ><br />De kleurweergaven in groen en
                                          rood gelden puur als visueel
                                          hulpmiddel om daar waar een eventuele
                                          oplettendheid, betreffende een
                                          discrepantie tussen de leest en
                                          ingevoerde maten, noodzakelijk is.
                                          Deze kleurweergaven geven in geen
                                          geval garantie van een correcte
                                          pasvorm of als leidend gelden voor de
                                          definitieve besluitvorming voor een
                                          juiste pasvorm.
                                        </q-tooltip>
                                      </q-btn>
                                    </p>
                                  </q-td>
                                </tr>
                                <tr
                                  style="padding: 1px"
                                  v-if="footData.ballwidth_right"
                                >
                                  <q-td>Balbreedte</q-td>
                                  <q-td style="text-align: center"
                                    ><p style="display: inline-flex; margin: 0">
                                      {{ footData.ballwidth_left }}
                                    </p>
                                  </q-td>
                                  <q-td
                                    style="
                                      background-color: rgba(
                                        230,
                                        230,
                                        230,
                                        0.5
                                      );
                                      text-align: center;
                                    "
                                    ><p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{ props.row.ballwidth_last }}
                                    </p>
                                  </q-td>
                                  <!--                                <q-td>Ingevoerd:</q-td>-->
                                  <q-td style="text-align: center">
                                    <p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{ props.row.ballwidth + " mm" }}
                                      <q-btn
                                        style="
                                          position: relative;
                                          left: 5px;
                                          top: -5px;
                                        "
                                        round
                                        flat
                                        icon="fal fa-info-square"
                                        size="xs"
                                      >
                                        <q-tooltip
                                          max-width="50%"
                                          style="font-size: 16px"
                                        >
                                          Het verschil in millimeters tussen de
                                          ingevoerde waarde en de waarde als
                                          weergegeven in de door de leverancier
                                          gehanteerde leesttabel.
                                          <br />Voor elk weergegeven leesttype
                                          wordt deze waarde in de betreffende
                                          rij weergegeven in groen of rood
                                          gearceerd. Groen geeft aan dat het
                                          verschil binnen een bovenmarge van 2
                                          millimeter (dit betekend aan de
                                          binnenzijde en/of buitenzijde maximaal
                                          1mm breder) of een ondermarge van -12
                                          millimeter (dit betekend aan de
                                          binnenzijde en/of buitenzijde maximaal
                                          6mm smaller) valt. Een roodgekleurde
                                          weergave geeft aan dat deze buiten
                                          deze marges valt. <br /><br /><strong
                                            >Let op!!</strong
                                          ><br />De kleurweergaven in groen en
                                          rood gelden puur als visueel
                                          hulpmiddel om daar waar een eventuele
                                          oplettendheid, betreffende een
                                          discrepantie tussen de leest en
                                          ingevoerde maten, noodzakelijk is.
                                          Deze kleurweergaven geven in geen
                                          geval garantie van een correcte
                                          pasvorm of als leidend gelden voor de
                                          definitieve besluitvorming voor een
                                          juiste pasvorm.
                                        </q-tooltip>
                                      </q-btn>
                                    </p>
                                  </q-td>
                                </tr>
                              </table>
                            </q-td>
                          </q-tr>
                        </template>
                      </q-table>
                    </ion-card-content>
                  </ion-card>
                </ion-col>
                <ion-col size-xs="12" size-sm="6">
                  <ion-card
                    class="custom-card"
                    style="
                      margin-left: 8px;
                      padding-left: 10px;
                      padding-bottom: 20px;
                    "
                  >
                    <!--                    <ion-card-header style="position: relative;left: -16px; width: 100%">-->
                    <!--                      <ion-label style="font-size: 24px">Links</ion-label>-->
                    <!--                      <ion-button><i class="fal fa-share-from-square"></i></ion-button>-->
                    <!--                    </ion-card-header>-->
                    <ion-card-content style="padding: 16px 0 16px 0">
                      <ion-item style="position: relative; left: -16px">
                        <ion-card-title>
                          <ion-label color="tertiary" style="font-size: 24px"
                            >Rechts
                          </ion-label>
                        </ion-card-title>
                      </ion-item>
                      <q-table
                        style="left: 3px; width: 96%; margin-top: 6px"
                        :hide-bottom="true"
                        bordered
                        ref="rightTable"
                        :rows="rowsRight"
                        wrap-cells
                        flat
                        :columns="columns"
                        row-key="name"
                        :visible-columns="[
                          'brand',
                          'lasttype',
                          'ballsize',
                          'size',
                          'ballwidth',
                        ]"
                        :pagination="{ rowsPerPage: 20 }"
                        :selected-rows-label="getSelectedString"
                        selection="multiple"
                        :selected="selected"
                        @selection="onSelection"
                        v-model:selected="selected"
                      >
                        <template v-slot:header="props">
                          <q-tr :props="props">
                            <!--                          <q-th  style="background-color: var(&#45;&#45;ion-color-tertiary-shade);color: var(&#45;&#45;ion-color-primary-contrast)"></q-th>-->
                            <q-th
                              style="
                                background-color: var(--ion-color-secondary);
                                color: white;
                              "
                              v-for="col in props.cols"
                              :key="col.name"
                              :props="props"
                              :style="col.name === 'name' ? 'display:none' : ''"
                            >
                              {{ col.label }}
                            </q-th>
                            <q-th
                              style="
                                background-color: var(--ion-color-tertiary);
                                color: white;
                              "
                            >
                              <i class="fa-solid fa-info"></i
                            ></q-th>
                          </q-tr>
                        </template>
                        {{ r }}
                        <template v-slot:body="props">
                          <q-tr
                            :props="props"
                            @click="
                              props.selected === true
                                ? (props.selected = false)
                                : (props.row.selected = true)
                            "
                            :id="props.row.lastgroup"
                          >
                            <!--                          <q-td>-->
                            <!--                            <q-checkbox v-model="props.selected"/>-->
                            <!--                          </q-td>-->
                            <template v-for="col in props.cols" :key="col.name">
                              <q-td
                                v-if="col.name === 'ballwidth'"
                                :style="[
                                  col.name === 'ballwidth' &&
                                  col.value < 2 &&
                                  col.value > -12
                                    ? 'background-color:rgba(0, 155, 0, 0.5);color:white'
                                    : 'background-color:rgba(155, 0, 0, 0.5);color:white',
                                  col.name !== 'ballwidth'
                                    ? 'background-color:transparent;color:var(--ion-color-dark)'
                                    : 'background-color:;color:',
                                  col.name === 'name' ? 'display:none' : '',
                                ]"
                              >
                                {{ col.value }}
                              </q-td>
                              <q-td
                                v-else
                                :style="[
                                  col.name === 'ballsize' &&
                                  col.value < 3 &&
                                  col.value > -5
                                    ? 'background-color:rgba(0, 155, 0, 0.5);color:white'
                                    : 'background-color:rgba(155, 0, 0, 0.5);color:white',
                                  col.name !== 'ballsize'
                                    ? 'background-color:transparent;color:var(--ion-color-dark)'
                                    : 'background-color:;color:',
                                  col.name === 'name' ? 'display:none' : '',
                                ]"
                              >
                                {{ col.value }}
                              </q-td>
                            </template>
                            <q-td>
                              <q-btn
                                size="xs"
                                color="info"
                                round
                                dense
                                @click="
                                  $event.stopPropagation();
                                  props.expand = !props.expand;
                                "
                                :icon="
                                  props.expand ? 'fal fa-minus' : 'fal fa-plus'
                                "
                              />
                            </q-td>
                          </q-tr>
                          <q-tr
                            v-if="props.expand"
                            :props="props"
                            style="
                              box-shadow: inset 0 2px 5px 0
                                rgba(200, 200, 200, 1);
                            "
                          >
                            <q-td
                              :colspan="footData.ballwidth_right ? 6 : 5"
                              style="padding: 1px"
                            >
                              <table
                                style="
                                  width: 96%;
                                  margin-bottom: 10px;
                                  margin-top: 10px;
                                  margin-left: 2%;
                                "
                              >
                                <tr style="padding: 1px">
                                  <q-th></q-th>
                                  <q-th
                                    style="
                                      border-bottom: 1px rgba(155, 155, 155, 1)
                                        solid;
                                    "
                                    >Ingevoerd
                                  </q-th>
                                  <q-th
                                    style="
                                      background-color: rgba(
                                        230,
                                        230,
                                        230,
                                        0.5
                                      );
                                      border-bottom: 1px rgba(155, 155, 155, 1)
                                        solid;
                                    "
                                  >
                                    Leest
                                    <q-btn
                                      style="
                                        position: relative;
                                        left: 5px;
                                        top: -5px;
                                      "
                                      round
                                      flat
                                      icon="fal fa-info-square"
                                      size="xs"
                                    >
                                      <q-tooltip
                                        max-width="50%"
                                        style="font-size: 16px"
                                      >
                                        De afgeronde waarde van de door de
                                        leverancier gehanteerde waarde zoals
                                        weergegeven in de leestabel behorende
                                        bij dit leesttype
                                      </q-tooltip>
                                    </q-btn>
                                  </q-th>
                                  <q-th
                                    style="
                                      border-bottom: 1px rgba(155, 155, 155, 1)
                                        solid;
                                    "
                                    >Verschil
                                  </q-th>
                                </tr>
                                <tr style="padding: 1px">
                                  <q-td>Lengte</q-td>
                                  <q-td style="text-align: center"
                                    ><p style="display: inline-flex; margin: 0">
                                      {{
                                        footData.footlength_right +
                                        " + " +
                                        footData.oversize_right +
                                        " = "
                                      }}
                                    </p>
                                    <p
                                      style="
                                        display: inline-flex;
                                        font-weight: bold;
                                        margin: 0 0 0 5px;
                                        font-size: 1.1em;
                                      "
                                    >
                                      {{
                                        parseInt(
                                          footData.footlength_incl_oversize_right
                                        )
                                      }}
                                    </p>
                                    <q-btn
                                      style="
                                        position: relative;
                                        left: 5px;
                                        top: -5px;
                                      "
                                      round
                                      flat
                                      icon="fal fa-info-square"
                                      size="xs"
                                    >
                                      <q-tooltip
                                        max-width="50%"
                                        style="font-size: 16px"
                                      >
                                        Het vetgedrukte is de totaal optelling
                                        van de ingevoerde voetlengte en de
                                        ingestelde overmaat
                                      </q-tooltip>
                                    </q-btn>
                                  </q-td>
                                  <q-td
                                    style="
                                      background-color: rgba(
                                        230,
                                        230,
                                        230,
                                        0.5
                                      );
                                      text-align: center;
                                    "
                                    ><p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{ parseInt(props.row.lastlength) }}
                                    </p>
                                  </q-td>
                                  <!--                                <q-td>Ingevoerd:</q-td>-->
                                  <q-td style="text-align: center">
                                    <p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{
                                        parseInt(
                                          props.row.entered_footlength_combined
                                        ) -
                                        parseInt(props.row.lastlength) +
                                        " mm"
                                      }}
                                      <q-btn
                                        style="
                                          position: relative;
                                          left: 5px;
                                          top: -5px;
                                        "
                                        round
                                        flat
                                        icon="fal fa-info-square"
                                        size="xs"
                                      >
                                        <q-tooltip
                                          max-width="50%"
                                          style="font-size: 16px"
                                        >
                                          De schoenmaat weergegeven in de rij
                                          direct boven deze tekst, is de
                                          schoenmaat die het dichst bij de
                                          ingevoerde voetlengte aansluit volgens
                                          de door de leverancier opgegeven
                                          leestlengte
                                        </q-tooltip>
                                      </q-btn>
                                    </p>
                                  </q-td>
                                </tr>
                                <tr style="padding: 1px">
                                  <q-td>Balomvang</q-td>
                                  <q-td style="text-align: center"
                                    ><p style="display: inline-flex; margin: 0">
                                      {{
                                        footData.ballsize_right +
                                        " + (" +
                                        footData.insole_right +
                                        "*2) = "
                                      }}
                                    </p>
                                    <p
                                      style="
                                        display: inline-flex;
                                        font-weight: bold;
                                        margin: 0 0 0 5px;
                                        font-size: 1.1em;
                                      "
                                    >
                                      {{
                                        parseInt(
                                          footData.ballsize_incl_insole_right
                                        )
                                      }}
                                    </p>
                                    <q-btn
                                      style="
                                        position: relative;
                                        left: 5px;
                                        top: -5px;
                                      "
                                      round
                                      flat
                                      icon="fal fa-info-square"
                                      size="xs"
                                    >
                                      <q-tooltip
                                        max-width="50%"
                                        style="font-size: 16px"
                                      >
                                        Het vetgedrukte is de totaal optelling
                                        van de ingevoerde balmaat plus de
                                        ingevoerde voetbeddikte (voetbeddikte
                                        vermenigvuldigd met 2 geeft het volume
                                        van het voetbed weer)
                                      </q-tooltip>
                                    </q-btn>
                                  </q-td>
                                  <q-td
                                    style="
                                      background-color: rgba(
                                        230,
                                        230,
                                        230,
                                        0.5
                                      );
                                      text-align: center;
                                    "
                                    ><p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{ parseInt(props.row.ballsize_last) }}
                                    </p>
                                  </q-td>
                                  <!--                                <q-td>Ingevoerd:</q-td>-->
                                  <q-td style="text-align: center">
                                    <p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{
                                        parseInt(
                                          props.row.ballsize_incl_insole
                                        ) -
                                        parseInt(props.row.ballsize_last) +
                                        " mm"
                                      }}
                                      <q-btn
                                        style="
                                          position: relative;
                                          left: 5px;
                                          top: -5px;
                                        "
                                        round
                                        flat
                                        icon="fal fa-info-square"
                                        size="xs"
                                      >
                                        <q-tooltip
                                          max-width="50%"
                                          style="font-size: 16px"
                                        >
                                          Het verschil in millimeters tussen de
                                          ingevoerde waarde en de waarde als
                                          weergegeven in de door de leverancier
                                          gehanteerde leesttabel.
                                          <br />Voor elk weergegeven leesttype
                                          wordt deze waarde in de betreffende
                                          rij weergegeven in groen of rood
                                          gearceerd. Groen geeft aan dat het
                                          verschil binnen een bovenmarge van 3
                                          millimeter (ruimer) of een ondermarge
                                          van -5 millimeter (krapper) valt. Een
                                          roodgekleurde weergave geeft aan dat
                                          deze buiten deze marges valt.
                                          <br /><br /><strong>Let op!!</strong
                                          ><br />De kleurweergaven in groen en
                                          rood gelden puur als visueel
                                          hulpmiddel om daar waar een eventuele
                                          oplettendheid, betreffende een
                                          discrepantie tussen de leest en
                                          ingevoerde maten, noodzakelijk is.
                                          Deze kleurweergaven geven in geen
                                          geval garantie van een correcte
                                          pasvorm of als leidend gelden voor de
                                          definitieve besluitvorming voor een
                                          juiste pasvorm.
                                        </q-tooltip>
                                      </q-btn>
                                    </p>
                                  </q-td>
                                </tr>
                                <tr
                                  style="padding: 1px"
                                  v-if="footData.ballwidth_right"
                                >
                                  <q-td>Balbreedte</q-td>
                                  <q-td style="text-align: center"
                                    ><p style="display: inline-flex; margin: 0">
                                      {{ footData.ballwidth_right }}
                                    </p>
                                  </q-td>
                                  <q-td
                                    style="
                                      background-color: rgba(
                                        230,
                                        230,
                                        230,
                                        0.5
                                      );
                                      text-align: center;
                                    "
                                    ><p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{ props.row.ballwidth_last }}
                                    </p>
                                  </q-td>
                                  <!--                                <q-td>Ingevoerd:</q-td>-->
                                  <q-td style="text-align: center">
                                    <p
                                      style="font-weight: bold; margin: 0 0 0 0"
                                    >
                                      {{ props.row.ballwidth + " mm" }}
                                      <q-btn
                                        style="
                                          position: relative;
                                          left: 5px;
                                          top: -5px;
                                        "
                                        round
                                        flat
                                        icon="fal fa-info-square"
                                        size="xs"
                                      >
                                        <q-tooltip
                                          max-width="50%"
                                          style="font-size: 16px"
                                        >
                                          Het verschil in millimeters tussen de
                                          ingevoerde waarde en de waarde als
                                          weergegeven in de door de leverancier
                                          gehanteerde leesttabel.
                                          <br />Voor elk weergegeven leesttype
                                          wordt deze waarde in de betreffende
                                          rij weergegeven in groen of rood
                                          gearceerd. Groen geeft aan dat het
                                          verschil binnen een bovenmarge van 2
                                          millimeter (dit betekend aan de
                                          binnenzijde en/of buitenzijde maximaal
                                          1mm breder) of een ondermarge van -12
                                          millimeter (dit betekend aan de
                                          binnenzijde en/of buitenzijde maximaal
                                          6mm smaller) valt. Een roodgekleurde
                                          weergave geeft aan dat deze buiten
                                          deze marges valt. <br /><br /><strong
                                            >Let op!!</strong
                                          ><br />De kleurweergaven in groen en
                                          rood gelden puur als visueel
                                          hulpmiddel om daar waar een eventuele
                                          oplettendheid, betreffende een
                                          discrepantie tussen de leest en
                                          ingevoerde maten, noodzakelijk is.
                                          Deze kleurweergaven geven in geen
                                          geval garantie van een correcte
                                          pasvorm of als leidend gelden voor de
                                          definitieve besluitvorming voor een
                                          juiste pasvorm.
                                        </q-tooltip>
                                      </q-btn>
                                    </p>
                                  </q-td>
                                </tr>
                              </table>
                            </q-td>
                          </q-tr>
                        </template>
                      </q-table>
                    </ion-card-content>
                  </ion-card>
                </ion-col>
                <q-page-sticky class="fixed-bottom-right" :offset="fabPos">
                  <q-btn-group rounded>
                    <q-btn
                      fab
                      tappable="true"
                      class="needsclick"
                      :loading="submitting"
                      rounded
                      style="
                        background: var(--ion-color-secondary-tint);
                        color: var(--ion-color-light);
                        padding-left: 30px;
                        padding-right: 20px;
                        border-right: 1px solid rgba(255, 255, 255, 0.85);
                      "
                      icon="fal fa-share-from-square"
                      :disable="draggingFab"
                      @click="shareSelectedModels()"
                      v-touch-pan.prevent.mouse="moveFab"
                    ><template v-slot:loading>
                      <q-spinner-hourglass class="on-left" />
                    </template></q-btn>
                    <q-btn
                      tappable="true"
                      style="
                        background: var(--ion-color-secondary-tint);
                        color: var(--ion-color-light);
                        padding-left: 30px;
                        padding-right: 20px;
                        display: flex;
                        flex-wrap: nowrap !important;
                      "
                      fab
                      rounded
                      :loading="submitting"
                      @click="goSelectedModels()"
                      icon-right="fal fa-chevron-right"
                      label="Modellen"
                      :disable="draggingFab"
                      v-touch-pan.prevent.mouse="moveFab"
                    >
                      <template v-slot:loading>
                        <q-spinner-hourglass class="on-left" />
                      </template>
                    </q-btn>
                  </q-btn-group>
                </q-page-sticky>
              </ion-row>
            </ion-grid>
          </q-page>
        </q-page-container>
      </q-layout>
    </ion-content>
    <!-- fab placed in the center of the content with a list on each side -->
  </ion-page>
</template>

<style>
.q-btn__content {
  flex-wrap: nowrap;
}
</style>

<style scoped>
ion-content {
  --padding-start: 0;
  --padding-end: 0;
}

.q-slider .q-slider__track-container--h {
  opacity: 0;
}

ion-row {
  height: 100%;
  /*margin-left: 4px;*/
  /*margin-right: 4px;*/
}

.selected {
  background-color: rgba(246, 141, 35, 0.5);
  color: white;
}

.q-table td {
  padding: 8px 8px;
  font-size: 10px;
}

.q-table th {
  padding: 9px 9px;
  font-size: 10px;
  font-weight: bold;
}

.q-table tr {
  padding: 8px 8px;
  font-size: 10px;
}

@media (min-width: 350px) {
}

/*###############################################################*/
@media (min-width: 576px) {
}

/*###############################################################*/
@media (min-width: 768px) {
}

/*###############################################################*/
@media (min-width: 992px) {
}

/*###############################################################*/
@media (min-width: 1024px) {
}

/*###############################################################*/
@media (min-width: 1150px) {
}
</style>
